/* eslint-disable max-lines-per-function, max-statements, no-ternary, complexity, max-lines */
const DEFAULT_LIST = [];
const DEFAULT_IS_ALL_AROUND_GATHER = false;
const DEFAULT_IS_MATCH_TITLE = false;
const DEFAULT_IS_ONLY_NEW = true;
const DEFAULT_IS_ONLY_PRESENT = false;
const DEFAULT_IS_SHOW_FLAGGED = false;
const DEFAULT_IS_TOGGLE_FLAGGED_LOADING = false;
const DEFAULT_IS_UHD = true;
const DEFAULT_NEXT_CURSOR = null;
// Selected Review
const DEFAULT_SELECTED = {
    id: "",
    isCreated: false,
    isPreviewLoading: false,
    isPreviewValid: false,
    url: ""
};
// Default Home Video Review to create
export const DEFAULT_HOME_VIDEO_REVIEW = {
    avForumsId: "",
    avNirvanaId: "",
    avsForumId: "",
    bluRayAuthorityId: "",
    bluRayId: "",
    digitalBitsId: "",
    doBluId: "",
    hidefDigestId: "",
    highDefDiscNewsId: "",
    homeTheaterForumId: "",
    imageServiceName: "bluRay",
    isBluRay: true,
    isPublished: false,
    isUHD: DEFAULT_IS_UHD,
    name: "",
    theaterByteId: "",
    whySoBluId: ""
};
const initalReviewsState = {
    display: DEFAULT_LIST,
    isAllAroundGather: DEFAULT_IS_ALL_AROUND_GATHER,
    isOnlyNew: DEFAULT_IS_ONLY_NEW,
    isOnlyPresent: DEFAULT_IS_ONLY_PRESENT,
    isShowFlagged: DEFAULT_IS_SHOW_FLAGGED,
    nextCursor: DEFAULT_NEXT_CURSOR,
    selected: DEFAULT_SELECTED
};
export const initialState = {
    avForums: initalReviewsState,
    avNirvana: initalReviewsState,
    avsForum: initalReviewsState,
    bluRay: initalReviewsState,
    bluRayAuthority: initalReviewsState,
    digitalBits: initalReviewsState,
    doBlu: initalReviewsState,
    hidefDigest: initalReviewsState,
    highDefDiscNews: initalReviewsState,
    homeTheaterForum: initalReviewsState,
    homeVideoReview: DEFAULT_HOME_VIDEO_REVIEW,
    isMatchTitle: DEFAULT_IS_MATCH_TITLE,
    isToggleFlaggedLoading: DEFAULT_IS_TOGGLE_FLAGGED_LOADING,
    isUHD: DEFAULT_IS_UHD,
    theaterByte: initalReviewsState,
    whySoBlu: initalReviewsState
};
export const RESET_SELECTED_REVIEW = "RESET_SELECTED_REVIEW";
export const RESET_SELECTED_REVIEWS = "RESET_SELECTED_REVIEWS";
export const SET_IMAGE_SERVICE_NAME = "SET_IMAGE_SERVICE_NAME";
export const SET_IS_ALL_AROUND_GATHER = "SET_IS_ALL_AROUND_GATHER";
export const SET_IS_MATCH_TITLE = "SET_IS_MATCH_TITLE";
export const SET_IS_ONLY_NEW = "SET_IS_ONLY_NEW";
export const SET_IS_ONLY_PRESENT = "SET_IS_ONLY_PRESENT";
export const SET_IS_SHOW_FLAGGED = "SET_IS_SHOW_FLAGGED";
export const SET_IS_TOGGLE_FLAGGED_LOADING = "SET_IS_TOGGLE_FLAGGED_LOADING";
export const SET_IS_UHD = "SET_IS_UHD";
export const SET_LIST = "SET_LIST";
export const SET_NAME = "SET_NAME";
export const SET_NEXT_CURSOR = "SET_NEXT_CURSOR";
export const SET_OMDB_ID = "SET_OMDB_ID";
export const SET_OVERRIDES = "SET_OVERRIDES";
export const SET_SELECTED_ID = "SET_SELECTED_ID";
export const SET_SELECTED_IS_PREVIEW_LOADING = "SET_SELECTED_IS_PREVIEW_LOADING";
export const SET_SELECTED_IS_PREVIEW_VALID = "SET_SELECTED_IS_PREVIEW_VALID";
export const SET_SELECTED_IS_REVIEW_CREATED = "SET_SELECTED_IS_REVIEW_CREATED";
export const SET_SELECTED_HOME_VIDEO_REVIEW = "SET_SELECTED_HOME_VIDEO_REVIEW";
const parseTitle = (title) => title
    // Remove year in parentheses
    .replace(/\(\d+\)$/u, "")
    .trim()
    // Remove 4K + 3D
    .replace(/4K \+ 3D/u, "")
    .trim()
    // Remove 4K
    .replace(/4K$/u, "")
    .trim()
    // Remove 3D
    .replace(/3D$/u, "")
    .trim();
const getSelectedTitleAndUrl = (state, selected) => {
    const result = state.display.find(({ id }) => id === selected);
    if (!result) {
        return { title: "", url: "" };
    }
    return {
        title: parseTitle(result.title),
        url: result.url
    };
};
const reducer = (
// eslint-disable-next-line default-param-last
state = initialState, action) => {
    switch (action.type) {
        case RESET_SELECTED_REVIEW: {
            return {
                ...state,
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    selected: DEFAULT_SELECTED
                }
            };
        }
        case RESET_SELECTED_REVIEWS: {
            return {
                avForums: { ...state.avForums, selected: DEFAULT_SELECTED },
                avNirvana: { ...state.avNirvana, selected: DEFAULT_SELECTED },
                avsForum: { ...state.avsForum, selected: DEFAULT_SELECTED },
                bluRay: { ...state.bluRay, selected: DEFAULT_SELECTED },
                bluRayAuthority: {
                    ...state.bluRayAuthority,
                    selected: DEFAULT_SELECTED
                },
                digitalBits: { ...state.digitalBits, selected: DEFAULT_SELECTED },
                doBlu: { ...state.doBlu, selected: DEFAULT_SELECTED },
                hidefDigest: { ...state.hidefDigest, selected: DEFAULT_SELECTED },
                highDefDiscNews: {
                    ...state.highDefDiscNews,
                    selected: DEFAULT_SELECTED
                },
                homeTheaterForum: {
                    ...state.homeTheaterForum,
                    selected: DEFAULT_SELECTED
                },
                homeVideoReview: { ...DEFAULT_HOME_VIDEO_REVIEW, isUHD: state.isUHD },
                isMatchTitle: state.isMatchTitle,
                isToggleFlaggedLoading: state.isToggleFlaggedLoading,
                isUHD: state.isUHD,
                theaterByte: {
                    ...state.theaterByte,
                    selected: DEFAULT_SELECTED
                },
                whySoBlu: { ...state.whySoBlu, selected: DEFAULT_SELECTED }
            };
        }
        case SET_IMAGE_SERVICE_NAME: {
            return {
                ...state,
                homeVideoReview: {
                    ...state.homeVideoReview,
                    imageServiceName: action.payload.imageServiceName
                }
            };
        }
        case SET_IS_ALL_AROUND_GATHER: {
            return {
                ...state,
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    isAllAroundGather: action.payload.isAllAroundGather
                }
            };
        }
        case SET_IS_MATCH_TITLE: {
            return {
                ...state,
                isMatchTitle: action.payload.isMatchTitle
            };
        }
        case SET_IS_ONLY_NEW: {
            return {
                ...state,
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    isOnlyNew: action.payload.isOnlyNew,
                    isOnlyPresent: action.payload.isOnlyNew
                        ? false
                        : state[action.payload.service].isOnlyPresent
                }
            };
        }
        case SET_IS_ONLY_PRESENT: {
            return {
                ...state,
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    isOnlyNew: action.payload.isOnlyPresent
                        ? false
                        : state[action.payload.service].isOnlyNew,
                    isOnlyPresent: action.payload.isOnlyPresent
                }
            };
        }
        case SET_IS_SHOW_FLAGGED: {
            return {
                ...state,
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    isShowFlagged: action.payload.isShowFlagged
                }
            };
        }
        case SET_IS_TOGGLE_FLAGGED_LOADING: {
            return {
                ...state,
                isToggleFlaggedLoading: action.payload.isToggleFlaggedLoading
            };
        }
        case SET_IS_UHD: {
            return {
                ...initialState,
                homeVideoReview: {
                    ...initialState.homeVideoReview,
                    isUHD: action.payload.isUHD
                },
                isUHD: action.payload.isUHD
            };
        }
        case SET_LIST: {
            return {
                ...state,
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    display: action.payload.list
                }
            };
        }
        case SET_NAME: {
            return {
                ...state,
                homeVideoReview: {
                    ...state.homeVideoReview,
                    name: action.payload.name
                }
            };
        }
        case SET_NEXT_CURSOR: {
            return {
                ...state,
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    nextCursor: action.payload.nextCursor
                }
            };
        }
        case SET_OMDB_ID: {
            return {
                ...state,
                homeVideoReview: {
                    ...state.homeVideoReview,
                    OMDbId: action.payload.OMDbId
                }
            };
        }
        case SET_OVERRIDES: {
            return {
                ...state,
                [`${action.payload.serviceName}`]: {
                    ...state[action.payload.serviceName],
                    selected: {
                        ...state[action.payload.serviceName].selected,
                        overrides: {
                            ...state[action.payload.serviceName].selected.overrides,
                            ...action.payload.overrides
                        }
                    }
                }
            };
        }
        case SET_SELECTED_ID: {
            const { title, url } = getSelectedTitleAndUrl(state[action.payload.service], action.payload.selected);
            return {
                ...state,
                homeVideoReview: {
                    ...state.homeVideoReview,
                    isUHD: state.isUHD,
                    // Always reset selected service id
                    [`${action.payload.service}Id`]: ""
                },
                isUHD: state.isUHD,
                matchTitle: title,
                // Add selected service to state
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    selected: {
                        ...state[action.payload.service].selected,
                        id: action.payload.selected,
                        isCreated: false,
                        isPreviewValid: false,
                        url
                    }
                }
            };
        }
        case SET_SELECTED_HOME_VIDEO_REVIEW: {
            return {
                ...state,
                homeVideoReview: {
                    ...state.homeVideoReview,
                    [`${action.payload.service}Id`]: action.payload.id,
                    isPublished: action.payload.isPublished
                },
                matchTitle: parseTitle(action.payload.title),
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    selected: {
                        ...state[action.payload.service].selected,
                        id: action.payload.id,
                        isCreated: action.payload.isCreated ?? false,
                        isPreviewValid: action.payload.isPreviewValid ?? false,
                        url: action.payload.url
                    }
                }
            };
        }
        case SET_SELECTED_IS_PREVIEW_LOADING: {
            return {
                ...state,
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    selected: {
                        ...state[action.payload.service].selected,
                        isPreviewLoading: action.payload.isPreviewLoading
                    }
                }
            };
        }
        case SET_SELECTED_IS_PREVIEW_VALID: {
            return {
                ...state,
                [`${action.payload.service}`]: {
                    ...state[action.payload.service],
                    selected: {
                        ...state[action.payload.service].selected,
                        isPreviewValid: action.payload.isPreviewValid
                    }
                }
            };
        }
        case SET_SELECTED_IS_REVIEW_CREATED: {
            return {
                ...state,
                homeVideoReview: {
                    ...state.homeVideoReview,
                    [`${action.payload.service}Id`]: action.payload.id
                }
            };
        }
        default: {
            return state;
        }
    }
};
export default reducer;
