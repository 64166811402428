/**
 * hasReleaseDate
 *
 * Returns true if the service is created
 * Note: id is optional
 *
 * @param {SelectedReviews} - The url of the service
 *
 * @returns {boolean} true if reviews have a release date
 */
const getHasReleaseDate = (reviews) => Object.values(reviews).some((review) => Boolean(review.overrides?.releaseDate));
export default getHasReleaseDate;
