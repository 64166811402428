import { H1 as BluBlocksH1 } from "@bluframe/blublocks"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

const H1 = styled(BluBlocksH1)`
  font-size: 22px;
  line-height: 1.1;
  margin-top: 0;

  ${mediaQueries.tablet} {
    font-size: 26px;
  }
`

export default H1
