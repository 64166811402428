import getIsServiceCreated from "./getIsServiceCreated";
/**
 * Determine if HomeVideoReview is ready to be updated based on the input properties
 * of various reviews and an ID.
 * @function getIsHomeVideoReviewReadyToBeUpdated
 * @param {HomeVideoReviewUpdatedProps} props - An object containing review properties
 * and HomeVideoReview ID.
 * @return {boolean} - If true, HomeVideoReview is considered ready to be updated; otherwise, false.
 */
const getIsHomeVideoReviewReadyToBeUpdated = ({ avForums, avNirvana, avsForum, bluRay, bluRayAuthority, digitalBits, doBlu, hidefDigest, highDefDiscNews, homeTheaterForum, id, homeVideoReview, theaterByte, whySoBlu }) => 
// Check if id exists, and if each review service is valid
// by calling getIsServiceCreated with the respective review's url and ID
Boolean(id) &&
    getIsServiceCreated(avForums.url, homeVideoReview.avForumsId) &&
    getIsServiceCreated(avNirvana.url, homeVideoReview.avNirvanaId) &&
    getIsServiceCreated(avsForum.url, homeVideoReview.avsForumId) &&
    getIsServiceCreated(bluRayAuthority.url, homeVideoReview.bluRayAuthorityId) &&
    getIsServiceCreated(bluRay.url, homeVideoReview.bluRayId) &&
    getIsServiceCreated(digitalBits.url, homeVideoReview.digitalBitsId) &&
    getIsServiceCreated(doBlu.url, homeVideoReview.doBluId) &&
    getIsServiceCreated(hidefDigest.url, homeVideoReview.hidefDigestId) &&
    getIsServiceCreated(highDefDiscNews.url, homeVideoReview.highDefDiscNewsId) &&
    getIsServiceCreated(homeTheaterForum.url, homeVideoReview.homeTheaterForumId) &&
    getIsServiceCreated(theaterByte.url, homeVideoReview.theaterByteId) &&
    getIsServiceCreated(whySoBlu.url, homeVideoReview.whySoBluId);
export default getIsHomeVideoReviewReadyToBeUpdated;
