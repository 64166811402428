import {
  ReviewsServiceName,
  ServiceLabel
} from "@bluframe/mightychroma-mightytool-frontend-types"

const serviceNameLabels: Record<ReviewsServiceName, ServiceLabel> = {
  avForums: "AV Forums",
  avNirvana: "AV Nirvana",
  avsForum: "AVS Forum",
  bluRay: "Blu-ray.com",
  bluRayAuthority: "Blu-ray Authority",
  digitalBits: "The Digital Bits",
  doBlu: "Do Blu",
  hidefDigest: "High-Def Digest",
  highDefDiscNews: "HighDefDiscNews.com",
  homeTheaterForum: "Home Theater Forum",
  theaterByte: "Theater Byte",
  whySoBlu: "Why So Blu?"
}

export default serviceNameLabels
