import Navigation from "./Navigation";
import { prepareComponent } from "@bluframe/grapple";
import useUser from "hooks/useUser";
const usePrepareComponent = (props) => {
    const { firstName, isAdmin, isEditor, isSocials } = useUser();
    return {
        ...props,
        firstName,
        isAdmin,
        isEditor,
        isSocials
    };
};
export default prepareComponent(usePrepareComponent)(Navigation);
