/* eslint-disable max-lines-per-function */
/* eslint-disable no-ternary */
import {
  CarouselContainer,
  CarouselInnerUl,
  CarouselSlideLi,
  NextButton,
  PrevButton
} from "./styled"
import { ComponentProps } from "."
import Icon from "components/Icon"

const Carousel = ({
  children,
  className,
  id,
  isShowArrows,
  name,
  onNext,
  onPrev,
  onTouchEnd,
  onTouchMove,
  onTouchStart,
  slidesToShow,
  translateX
}: ComponentProps) => (
  <CarouselContainer
    aria-atomic="true"
    aria-label={name ? `${name} Carousel` : "Carousel"}
    aria-live="polite"
    className={className}
    onTouchEnd={onTouchEnd}
    onTouchMove={onTouchMove}
    onTouchStart={onTouchStart}
  >
    <CarouselInnerUl
      $translateX={translateX}
      aria-label={name ? `${name} Carousel List` : "Carousel List"}
    >
      {children.map((child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <CarouselSlideLi $slidesToShow={slidesToShow} key={`${id}-${index}`}>
          {child}
        </CarouselSlideLi>
      ))}
    </CarouselInnerUl>
    {isShowArrows ? (
      <>
        <PrevButton
          icon={<Icon group="io5" name="IoChevronBack" size="36px" />}
          iconOnly
          label="Prev"
          noHoverHighlight
          onClick={onPrev}
        />
        <NextButton
          icon={<Icon group="io5" name="IoChevronForward" size="36px" />}
          iconOnly
          label="Next"
          noHoverHighlight
          onClick={onNext}
        />
      </>
    ) : null}
  </CarouselContainer>
)

export default Carousel
