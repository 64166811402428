import gql from "graphql-tag"

const SOCIALS_AI_INSTRUCTION_ADD = gql`
  mutation socialsAiInstructionAdd(
    $socialsAiInstructionAddInput: SocialsAiInstructionAddInput!
  ) {
    socialsAiInstructionAdd(
      socialsAiInstructionAddInput: $socialsAiInstructionAddInput
    ) {
      id
    }
  }
`
export default SOCIALS_AI_INSTRUCTION_ADD
