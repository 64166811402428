import {
  NewsServiceName,
  ReviewsServiceName
} from "@bluframe/mightychroma-mightytool-frontend-types"
import ImageSelector from "./ImageSelector"
import { ModalProps } from "@bluframe/blublocks"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import { useState } from "react"

export interface Image {
  serviceName: NewsServiceName | ReviewsServiceName
  url: string
}

export interface Props {
  imageReviewsServiceNameSelected?: NewsServiceName | ReviewsServiceName
  images: Image[]
  modal: Omit<ModalProps, "children">
  onSelectClick: () => void
  onSelectFrontImage: (
    // eslint-disable-next-line no-unused-vars
    serviceName: NewsServiceName | ReviewsServiceName | undefined
  ) => void
}

export interface ComponentProps extends Omit<Props, "onSelectFrontImage"> {
  imageReviewsServiceNameSelected?: NewsServiceName | ReviewsServiceName
  onConfirm: () => void
  onSelectImage: (
    // eslint-disable-next-line no-unused-vars
    serviceName: NewsServiceName | ReviewsServiceName
  ) => () => void
}

export const usePrepareComponent = (props: Props): ComponentProps => {
  const [
    newImageReviewsServiceNameSelected,
    setNewImageReviewsServiceNameSelected
  ] = useState<NewsServiceName | ReviewsServiceName | undefined>(
    () => props.imageReviewsServiceNameSelected
  )

  const onConfirm = () => {
    props.onSelectFrontImage(newImageReviewsServiceNameSelected)

    props.modal.onClose()
  }

  const onSelectImage =
    (serviceName: NewsServiceName | ReviewsServiceName) => () => {
      if (serviceName === newImageReviewsServiceNameSelected) {
        return
      }

      setNewImageReviewsServiceNameSelected(serviceName)
    }

  return {
    ...props,
    imageReviewsServiceNameSelected: newImageReviewsServiceNameSelected,
    onConfirm,
    onSelectImage
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  ImageSelector
)
