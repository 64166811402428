export const getIsServiceValid = (url, isPreviewValid) => (url && isPreviewValid) || !url;
export const getIsServiceCreated = (url, isPreviewValid, id) => (Boolean(url) && isPreviewValid && Boolean(id)) || !url;
export const getIsAddNewsEnabled = ({ news, services }) => news.authorId !== null &&
    getIsServiceValid(services.avNirvana.url, services.avNirvana.isPreviewValid) &&
    getIsServiceValid(services.bluRay.url, services.bluRay.isPreviewValid) &&
    getIsServiceValid(services.hidefDigest.url, services.hidefDigest.isPreviewValid) &&
    getIsServiceValid(services.whySoBlu.url, services.whySoBlu.isPreviewValid);
export const getIsNewsReadyToBeCreated = ({ avNirvana, bluRay, hidefDigest, news, whySoBlu }) => getIsServiceCreated(avNirvana.url, avNirvana.isPreviewValid, news.avNirvanaId) &&
    getIsServiceCreated(bluRay.url, bluRay.isPreviewValid, news.bluRayId) &&
    getIsServiceCreated(hidefDigest.url, hidefDigest.isPreviewValid, news.hidefDigestId) &&
    getIsServiceCreated(whySoBlu.url, whySoBlu.isPreviewValid, news.whySoBluId);
