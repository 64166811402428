/* eslint-disable no-ternary */
import { CheckboxProps, Option, SelectProps } from "@bluframe/blublocks"
import {
  HomeVideoReviewSearchItem,
  NewsSearchItem,
  SocialsGeneratePost,
  SocialsPlatform,
  SocialsPlatformServiceName
} from "@bluframe/mightychroma-mightytool-frontend-types"
import { RefObject, useEffect, useRef, useState } from "react"
import { Props as AutosuggestProps } from "components/Autosuggest"
import GeneratePosts from "./GeneratePosts"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface Props {
  arePlatformsLoading: boolean
  generatedPosts?: Partial<
    Record<SocialsPlatformServiceName, SocialsGeneratePost>
  >
  homeVideoReviewTitles: HomeVideoReviewSearchItem[]
  isAutosuggestLoading: boolean
  isGenerateDisabled: boolean
  isGenerateLoading: boolean
  newsTitles: NewsSearchItem[]
  onGetHomeVideoReviewsSuggestions: AutosuggestProps["onGetSuggestions"]
  onGetNewsSuggestions: AutosuggestProps["onGetSuggestions"]
  onClearAllClick: () => void
  // eslint-disable-next-line no-unused-vars
  onClearClick: (platform: SocialsPlatformServiceName) => () => void
  // eslint-disable-next-line no-unused-vars
  onGenerateAllClick: (additionalInstructions?: string) => void
  // eslint-disable-next-line no-unused-vars
  onGenerateClick: (platform: SocialsPlatformServiceName) => () => void
  // eslint-disable-next-line no-unused-vars
  onSelectHomeVideoReviewId: (id: string, name: string) => void
  // eslint-disable-next-line no-unused-vars
  onSelectNewsId: (id: string, name: string) => void
  platformsCheckboxes: CheckboxProps[]
  selectedHomeVideoReviewId: string
  selectedNewsId: string
  socialsPlatforms: SocialsPlatform[]
}

type SelectedType = "homeVideoReviews" | "news"

const types: Option[] = [
  {
    label: "Home Video Reviews",
    value: "homeVideoReviews"
  },
  {
    label: "News",
    value: "news"
  }
]

export interface ComponentProps extends Omit<Props, "onGenerateAllClick"> {
  generatedPostsCounter: number
  onChangeType: SelectProps["onChange"]
  onGenerateAllClick: () => void
  selectedType?: SelectedType
  textAreaRef: RefObject<HTMLTextAreaElement>
  types: Option[]
}

const DEFAULT_GENERATED_COUNTER = 0
const ONE = 1

const usePrepareComponent = (props: Props): ComponentProps => {
  const [selectedType, setSelectedType] = useState<SelectedType>()
  const [generatedPostsCounter, setGeneratedPostsCounter] = useState(
    DEFAULT_GENERATED_COUNTER
  )
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const onChangeType: SelectProps["onChange"] = (type) => {
    if (type === "homeVideoReviews" || type === "news") {
      setSelectedType(type)
    }
  }

  const onGenerateAllClick = () => {
    props.onGenerateAllClick(
      textAreaRef.current?.value === "" ? undefined : textAreaRef.current?.value
    )
  }

  useEffect(() => {
    setGeneratedPostsCounter(
      (prevGeneratedPostsCounter) => prevGeneratedPostsCounter + ONE
    )
  }, [props.generatedPosts])

  return {
    ...props,
    generatedPostsCounter,
    onChangeType,
    onGenerateAllClick,
    selectedType,
    textAreaRef,
    types
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  GeneratePosts
)
