import items, { Item } from "./items"
import Navigation from "./Navigation"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import { useState } from "react"

export interface Props {
  isAdmin: boolean
  isEditor: boolean
  isExpanded: boolean
  isSocials: boolean
  name: string
  onToggleIsExpanded: () => void
}

export interface ComponentProps extends Props {
  items: Item[]
  // eslint-disable-next-line no-unused-vars
  onSetOpenSubItems: (id: string | null) => () => void
  openSubItems: string | null
}

const usePrepareComponent = (props: Props): ComponentProps => {
  const [openSubItems, setOpenSubItems] = useState<string | null>(null)

  const onSetOpenSubItems = (id: string | null) => () => {
    setOpenSubItems(id)
  }

  return {
    ...props,
    items,
    onSetOpenSubItems,
    openSubItems
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Navigation
)
