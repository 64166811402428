import gql from "graphql-tag"

const SOCIALS_AI_INSTRUCTION_UPDATE = gql`
  mutation socialsAiInstructionUpdate(
    $socialsAiInstructionUpdateInput: SocialsAiInstructionUpdateInput!
  ) {
    socialsAiInstructionUpdate(
      socialsAiInstructionUpdateInput: $socialsAiInstructionUpdateInput
    ) {
      id
    }
  }
`
export default SOCIALS_AI_INSTRUCTION_UPDATE
