import gql from "graphql-tag"
import socialsPlatform from "fragments/socialsPlatform"

const SOCIALS_PLATFORM_ADD = gql`
  ${socialsPlatform}

  mutation socialsPlatformAdd(
    $socialsPlatformAddInput: SocialsPlatformAddInput!
  ) {
    socialsPlatformAdd(socialsPlatformAddInput: $socialsPlatformAddInput) {
      ...SocialsPlatform
    }
  }
`

export default SOCIALS_PLATFORM_ADD
