import React, {
  ChangeEvent,
  FocusEvent,
  ForwardedRef,
  useMemo,
  useState
} from "react"
import TextArea from "./TextArea"
import { prepareComponent } from "@bluframe/grapple"

export interface Props {
  className?: string
  cols?: number
  defaultValue?: string
  disabled?: boolean
  error?: boolean
  errorText?: string
  infoText?: string
  inputId?: string
  isExpanded?: boolean
  isFullBorder?: boolean
  label?: React.ReactNode
  // eslint-disable-next-line no-unused-vars
  onBlur?: (event: FocusEvent) => void
  // eslint-disable-next-line no-unused-vars
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void
  maxChars?: number
  name: string
  placeholder?: string
  ref?: ForwardedRef<HTMLTextAreaElement>
  required?: boolean
  rows?: number
  success?: boolean
  successText?: string
  type?: string
  value?: string
}

export interface ComponentProps extends Omit<Props, "maxChars"> {
  charactersCountLabel?: string
  isMaxCharactersExceeded?: boolean
  labelId?: string
}

const DEFAULT_CHARS = 0

const usePrepareComponent = ({ maxChars, ...props }: Props): ComponentProps => {
  const [charactersCount, setCharactersCount] = useState<number>(
    () => props.defaultValue?.length ?? DEFAULT_CHARS
  )
  const labelId = props.inputId && `${props.inputId}-label`

  const onBlur = (event: FocusEvent) => {
    if (props.onBlur) {
      props.onBlur(event)
    }
  }

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCharactersCount(event.target.value.length)

    if (props.onChange) {
      props.onChange(event)
    }
  }

  const charactersCountLabel = useMemo(() => {
    if (maxChars) {
      return `${charactersCount} / ${maxChars}`
    }

    return undefined
  }, [charactersCount, maxChars])

  const isMaxCharactersExceeded = useMemo(() => {
    if (maxChars) {
      return charactersCount > maxChars
    }

    return undefined
  }, [charactersCount, maxChars])

  return {
    ...props,
    charactersCountLabel,
    isMaxCharactersExceeded,
    labelId,
    onBlur,
    onChange
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  TextArea
)
