/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-ternary */
import {
  Actions,
  Description,
  Form,
  Info,
  Main,
  Name,
  NameAndInfo,
  Top
} from "./styled"
import {
  ConfirmModal,
  Content,
  InputText,
  Modal,
  TextArea
} from "@bluframe/blublocks"
import Button from "components/buttons/Button"
import { ComponentProps } from "."

const Item = ({
  actionButtonLabel,
  actionButtonOnClick,
  areActionsDisabled,
  contentRef,
  descriptionRef,
  identifierRef,
  isEditMode,
  isModalOpen,
  isSaveLoading,
  item,
  modalContent,
  nameRef,
  onModalClose,
  onModalDeleteConfirm,
  onModalSaveConfirm,
  onSubmit,
  orderRef,
  outlinedButtonLabel,
  outlinedButtonOnClick
}: ComponentProps) => (
  <Form onSubmit={onSubmit}>
    <Top>
      <Main>
        <NameAndInfo $isEditMode={isEditMode}>
          {isEditMode ? (
            <>
              <InputText
                defaultValue={item?.name}
                inputId="name"
                label="Name"
                name="name"
                ref={nameRef}
                required
              />
              <TextArea
                defaultValue={item?.description}
                inputId="description"
                label="Description"
                name="description"
                ref={descriptionRef}
                rows={3}
              />
              <InputText
                defaultValue={item?.identifier}
                inputId="identifier"
                label="Identifier"
                name="identifier"
                ref={identifierRef}
                required
              />
              <InputText
                defaultValue={item?.order.toString()}
                inputId="order"
                label="Order"
                name="order"
                ref={orderRef}
                required
                type="number"
              />
            </>
          ) : (
            <>
              <Name>{item?.name}</Name>
              <Description>{item?.description}</Description>
              <Info>{item?.identifier}</Info>
              <Info>{item?.order}</Info>
            </>
          )}
        </NameAndInfo>
        {isEditMode ? (
          <TextArea
            defaultValue={item?.content}
            inputId="content"
            label="Content"
            name="content"
            ref={contentRef}
            required
          />
        ) : item?.content ? (
          <Content content={item?.content} />
        ) : null}
      </Main>
      <Actions aria-label="Actions" role="region">
        <Button
          disabled={areActionsDisabled}
          label={outlinedButtonLabel}
          onClick={outlinedButtonOnClick}
          outlined
        />
        <Button
          disabled={areActionsDisabled}
          label={actionButtonLabel}
          loading={isEditMode ? isSaveLoading : false}
          onClick={actionButtonOnClick}
          type={isEditMode ? "submit" : "button"}
        />
      </Actions>
    </Top>

    <Modal isOpen={isModalOpen} onClose={onModalClose}>
      {modalContent === "deleteConfirm" ? (
        <ConfirmModal
          message={`Are you sure you want to delete ${item?.name}?`}
          onClose={onModalClose}
          onConfirm={onModalDeleteConfirm}
        />
      ) : modalContent === "saveConfirm" ? (
        <ConfirmModal
          message={`Are you sure you want to save ${
            item?.name ?? nameRef.current?.value
          }?`}
          onClose={onModalClose}
          onConfirm={onModalSaveConfirm}
        />
      ) : null}
    </Modal>
  </Form>
)

export default Item
