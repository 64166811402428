/* eslint-disable no-ternary, max-lines-per-function */

import { Bottom, H4, Li, Logo, Name, Toggle, Top, Ul, Wrapper } from "./styled"
import { Button, Icon } from "@bluframe/blublocks"
import { ComponentProps } from "."
import Item from "./Item"
import { Link } from "react-router-dom"
import theme from "styles/theme"

const Navigation = ({
  isAdmin,
  isEditor,
  isExpanded,
  isSocials,
  items,
  name,
  onSetOpenSubItems,
  onToggleIsExpanded,
  openSubItems
}: ComponentProps) => (
  <Wrapper $isExpanded={isExpanded}>
    <Top $isExpanded={isExpanded}>
      <Logo
        alt="Mighty Tool Logo"
        src="https://snowflakes.blufra.me/sites/mightychroma.me/content/xsmall_Logo_Social_Circle_Bold_6c5a8b3ecc.png"
      />
      {isExpanded ? <H4>{"Mighty Tool"}</H4> : null}
    </Top>

    <nav aria-label="Mighty Tool Navigation">
      <Ul aria-label="Navigation Items">
        {items
          .filter((item) => {
            if (isAdmin) {
              return true
            }

            if (isEditor) {
              return item.isEditor
            }

            if (isSocials) {
              return item.isSocials
            }

            return false
          })
          .map(({ isAdmin: __, ...item }) => (
            <Li aria-label={item.label} key={item.link}>
              <Item
                {...item}
                isAdmin={isAdmin}
                isEditor={isEditor}
                isExpanded={isExpanded}
                isShowSubItems={openSubItems === item.link}
                isSocials={isSocials}
                onSetOpenSubItems={onSetOpenSubItems(item.link ?? null)}
              />
            </Li>
          ))}
      </Ul>
    </nav>

    <Bottom $isExpanded={isExpanded}>
      <Link aria-label="Profile" to="/profile">
        <Icon
          color={theme.palette.subtitle.main}
          name="IoPerson"
          size="18"
          title="Profile"
        />
      </Link>
      {isExpanded ? (
        <Link to="/profile">
          <Name>{name}</Name>
        </Link>
      ) : null}
    </Bottom>

    <Toggle $isExpanded={isExpanded}>
      <Button
        icon={
          <Icon
            color={theme.palette.subtitle.main}
            name="IoChevronBackCircleOutline"
            size="24"
          />
        }
        iconOnly
        label="Toggle"
        onClick={onToggleIsExpanded}
        rounded
      />
    </Toggle>
  </Wrapper>
)

export default Navigation
