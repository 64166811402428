import Route from "./Route";
import isTokenExpired from "./isTokenExpired";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useHistory } from "react-router-dom";
import useUser from "hooks/useUser";
const usePrepareComponent = ({ component, exact, isAdminOnly, isEditorOnly, isLoggedInOnly, isSocialsOnly, path }) => {
    const history = useHistory();
    const { isAdmin, isEditor, isSocials, onClearToken, onGetToken } = useUser();
    const token = onGetToken();
    /**
     *  If the user is not logged in and route is only
     *  for logged in users, redirect to login page.
     */
    if (isLoggedInOnly && (!token || isTokenExpired(token))) {
        onClearToken();
        return history.push("/login");
    }
    /**
     *  If the user is not an admin, editor, or socials,
     * and the route is only for one of those roles,
     * redirect to home page.
     * Admins can access all routes.
     */
    if ((isAdminOnly && !isAdmin) ||
        (isEditorOnly && !isEditor && !isAdmin) ||
        (isSocialsOnly && !isSocials && !isAdmin)) {
        return history.push("/");
    }
    return { component, exact, path };
};
// @ts-ignore
export default prepareComponent(usePrepareComponent)(Route);
