import { gql } from "graphql-tag"

const critics = gql`
  type Mutation {
    addReview(reviewAddInput: ReviewAddInput!): ReviewResponse!

    addPreview(previewAddInput: PreviewAddInput!): Preview!

    validatePreview(
      previewValidateInput: PreviewAddInput!
    ): PreviewValidateResponse!

    updateReview(
      serviceName: String!
      reviewUpdateInput: ReviewUpdateInput!
    ): ReviewResponse!

    updateCriticsReviewFromUrl(
      id: ID!
      url: String!
      serviceName: String!
    ): ReviewResponse!
  }
`

export default critics
