"use client"
/* eslint-disable max-lines-per-function, max-statements */

import React, { useCallback, useEffect, useId, useMemo, useState } from "react"
import Carousel from "./Carousel"
import mediaQueries from "styles/mediaQueries"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface Props {
  children: React.ReactNode
  className?: string
  id?: string
  name?: string
  onNext?: () => void
  onPrev?: () => void
  slidesToScroll?: number
  slidesToShow?: number
  slidesToShowDesktop?: number
  slidesToShowMobile?: number
  slidesToShowTablet?: number
}

export interface ComponentProps extends Omit<Props, "slidesToScroll"> {
  children: React.ReactNode[]
  id: string
  isShowArrows: boolean
  onNext: () => void
  onPrev: () => void
  // eslint-disable-next-line no-unused-vars
  onTouchStart: (event: React.TouchEvent<HTMLDivElement>) => void
  // eslint-disable-next-line no-unused-vars
  onTouchMove: (event: React.TouchEvent<HTMLDivElement>) => void
  onTouchEnd: () => void
  slidesToShow: number
  translateX: number
}

const DEFAULT_SLIDES_TO_SCROLL = 1
const DEFAULT_SLIDES_TO_SHOW = 1

const DEFAULT_ACTIVE_INDEX = 0
const DEFAULT_TRANSLATE_X = 0

const INITIAL_SLIDES_TO_SHOW = 0

const FIRST = 0
const TOTAL = 100
const HALF = 50

const usePrepareComponent = ({
  children,
  slidesToScroll = DEFAULT_SLIDES_TO_SCROLL,
  slidesToShow: slideToShowProps = DEFAULT_SLIDES_TO_SHOW,
  slidesToShowDesktop = slideToShowProps,
  slidesToShowMobile = slideToShowProps,
  slidesToShowTablet = slideToShowProps,
  ...props
}: Props): ComponentProps => {
  // If children isn't an array throw error
  if (!Array.isArray(children)) {
    throw new Error("Children must be an array of React nodes")
  }

  const id = useId()

  const [activeIndex, setActiveIndex] = useState(DEFAULT_ACTIVE_INDEX)
  const [translateX, setTranslateX] = useState(DEFAULT_TRANSLATE_X)
  const [touchStart, setTouchStart] = useState<number | null>(null)
  const [touchMove, setTouchMove] = useState<number | null>(null)

  /**
   * getSlidesToShow
   * @description Determines the number of slides to show based on the current screen size
   * and the provided props
   * DO NOT USE OUTSIDE OF useEffect if we are using SSR
   * @returns {number} The number of slides to show
   * */
  const getSlidesToShow = useCallback(() => {
    if (
      window.matchMedia(
        mediaQueries.desktop.replace("@media only screen and ", "")
      ).matches
    ) {
      return slidesToShowDesktop
    } else if (
      window.matchMedia(
        mediaQueries.tablet.replace("@media only screen and ", "")
      ).matches
    ) {
      return slidesToShowTablet
    }

    return slidesToShowMobile
  }, [slidesToShowDesktop, slidesToShowMobile, slidesToShowTablet])

  const [slidesToShow, setSlidesToShow] = useState(INITIAL_SLIDES_TO_SHOW)

  useEffect(() => {
    setSlidesToShow(getSlidesToShow())
  }, [getSlidesToShow])

  useEffect(() => {
    const onResize = () => {
      const newSlidesToShow = getSlidesToShow()

      setSlidesToShow(newSlidesToShow)

      setTranslateX(-activeIndex * (TOTAL / slidesToShow))
    }

    onResize()

    window.addEventListener("resize", onResize)

    return () => window.removeEventListener("resize", onResize)
  }, [
    activeIndex,
    getSlidesToShow,
    slidesToShow,
    slidesToShowDesktop,
    slidesToShowMobile,
    slidesToShowTablet
  ])

  useEffect(() => {
    setTranslateX(-activeIndex * (TOTAL / slidesToShow))
  }, [activeIndex, slidesToShow])

  const onNext = useCallback(() => {
    props.onNext?.()

    setActiveIndex((prevIndex) => {
      const maxIndex = children.length - slidesToShow

      if (prevIndex >= maxIndex) {
        // Loop back to the start
        return DEFAULT_ACTIVE_INDEX
      }

      return Math.min(prevIndex + slidesToScroll, maxIndex)
    })
  }, [props, children.length, slidesToShow, slidesToScroll])

  const onPrev = useCallback(() => {
    props.onPrev?.()

    setActiveIndex((prevIndex) => {
      if (prevIndex === DEFAULT_ACTIVE_INDEX) {
        // If at the beginning, loop to the last valid starting index
        return children.length - slidesToShow
      }

      // Otherwise, move backward
      return Math.max(prevIndex - slidesToScroll, DEFAULT_ACTIVE_INDEX)
    })
  }, [props, slidesToScroll, children.length, slidesToShow])

  const onTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    setTouchStart(event.touches[FIRST].clientX)
  }

  const onTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    setTouchMove(event.touches[FIRST].clientX)
  }

  const onTouchEnd = () => {
    if (touchStart && touchMove) {
      const swipeDistance = touchMove - touchStart
      if (swipeDistance > HALF) {
        onPrev()
      } else if (swipeDistance < -HALF) {
        onNext()
      }
    }
    setTouchStart(null)
    setTouchMove(null)
  }

  const isShowArrows = useMemo(
    () => children.length > slidesToShow,
    [children.length, slidesToShow]
  )

  return {
    ...props,
    children,
    id: props.id ?? id,
    isShowArrows,
    onNext,
    onPrev,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
    slidesToShow,
    translateX
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Carousel
)
