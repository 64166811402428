/* eslint-disable no-ternary */

import { Li, Name, SuggestionPadding, Wrapper } from "./styled"
import { ComponentProps } from "."
import Image from "components/Image"
import { Loading } from "@bluframe/blublocks"

const Suggestion = ({
  ariaLabel,
  image,
  isLoading,
  itemProps: { onMouseDown, onMouseMove, ref, role, ...itemProps },
  onClick,
  name
}: ComponentProps) => (
  <Li
    aria-disabled={itemProps["aria-disabled"]}
    aria-label={ariaLabel}
    aria-selected={itemProps["aria-selected"]}
    onClick={onClick}
    onMouseDown={onMouseDown}
    onMouseMove={onMouseMove}
    ref={ref}
    role={role}
  >
    {isLoading ? (
      <SuggestionPadding>
        <Loading size={24} />
      </SuggestionPadding>
    ) : (
      <Wrapper $hasImage={Boolean(image)}>
        {image ? (
          <Image
            alternativeText={image.alternativeText}
            formats={image.formats}
            height={image.height}
            name={image.name}
            sizes="(min-width: 1200px) 2vw, (min-width: 1024px) 2vw, (min-width: 768px) 2vw, 2vw"
            url={image.url}
            width={image.width}
          />
        ) : null}

        <Name>{name}</Name>
      </Wrapper>
    )}
  </Li>
)

Suggestion.displayName = "Suggestion"

export default Suggestion
