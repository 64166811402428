import criticsReview from "./criticsReview"
import gql from "graphql-tag"
import mas from "./mas"

const GET_HOME_VIDEO_REVIEW = gql`
  ${criticsReview}
  ${mas}

  fragment HomeVideoReview on HomeVideoReview {
    avForums {
      ...CriticsReview
    }
    avNirvana {
      ...CriticsReview
    }
    avsForum {
      ...CriticsReview
    }
    bluRay {
      ...CriticsReview
    }
    bluRayAuthority {
      ...CriticsReview
    }
    digitalBits {
      ...CriticsReview
    }
    doBlu {
      ...CriticsReview
    }
    hidefDigest {
      ...CriticsReview
    }
    highDefDiscNews {
      ...CriticsReview
    }
    homeTheaterForum {
      ...CriticsReview
    }
    id
    isPublished
    mas {
      ...MAS
    }
    name
    score {
      audio
      extra
      movie
      total
      video
    }
    summary {
      audio {
        long
        short
      }
      extra {
        long
        short
      }
      movie {
        long
        short
      }
      total {
        long
        short
      }
      video {
        long
        short
      }
    }
    theaterByte {
      ...CriticsReview
    }
    whySoBlu {
      ...CriticsReview
    }
  }
`

export default GET_HOME_VIDEO_REVIEW
