/* eslint-disable no-ternary, max-lines-per-function, no-nested-ternary, max-statements */
import { mutations, queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import Item from "./Item";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useToggle from "@bluframe/grapple/useToggle";
const { socials: { GET_SOCIALS_AI_INSTRUCTIONS, GET_SOCIALS_AI_INSTRUCTION_BY_ID } } = queries;
const { socials: { SOCIALS_AI_INSTRUCTION_DELETE, SOCIALS_AI_INSTRUCTION_UPDATE } } = mutations;
const usePrepareComponent = () => {
    const [isEditing, toggleIsEditing] = useToggle();
    const history = useHistory();
    const params = useParams();
    const { data, loading } = useQuery(GET_SOCIALS_AI_INSTRUCTION_BY_ID, {
        variables: {
            id: params.id
        }
    });
    const [updateSocialsAiInstruction, { loading: isSaveLoading }] = useMutation(SOCIALS_AI_INSTRUCTION_UPDATE, {
        refetchQueries: [
            {
                query: GET_SOCIALS_AI_INSTRUCTION_BY_ID,
                variables: { id: params.id }
            }
        ]
    });
    const [deleteSocialsAiInstruction] = useMutation(SOCIALS_AI_INSTRUCTION_DELETE, {
        refetchQueries: [{ query: GET_SOCIALS_AI_INSTRUCTIONS }]
    });
    const onCancel = () => {
        history.push("/socials/ai-instructions");
    };
    const onEdit = () => {
        toggleIsEditing();
    };
    const onSave = (socialsAiInstructionUpdateInput) => {
        updateSocialsAiInstruction({
            onCompleted: () => {
                toggleIsEditing();
            },
            variables: {
                socialsAiInstructionUpdateInput: {
                    ...socialsAiInstructionUpdateInput,
                    id: params.id
                }
            }
        });
    };
    const onDelete = () => {
        deleteSocialsAiInstruction({
            onCompleted: () => {
                history.push("/socials/ai-instructions");
            },
            variables: {
                id: params.id
            }
        });
    };
    return {
        isEditing,
        isSaveLoading,
        item: data?.getSocialsAiInstructionById,
        loading,
        onCancel,
        onDelete,
        onEdit,
        onSave
    };
};
export default prepareComponent(usePrepareComponent)(Item);
