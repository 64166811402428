/* eslint-disable no-ternary, max-lines-per-function */

import {
  Actions,
  Left,
  Middle,
  Preview,
  Previews,
  ServiceName,
  Title,
  Wrapper
} from "./styled"
import Button from "components/buttons/Button"
import { Checkbox } from "@bluframe/blublocks"
import CollapsibleTextArea from "components/inputs/CollapsibleTextArea"
import { ComponentProps } from "."
import serviceNameLabels from "config/serviceNameLabels"

const Draft = ({
  id,
  isChecked,
  isGenerateLoading,
  isPublishLoading,
  onChange,
  onEdit,
  onGenerate,
  onPublish,
  previews,
  textAreaRef,
  title
}: ComponentProps) => (
  <Wrapper>
    <Left>
      <Checkbox
        inputId={id}
        isChecked={isChecked}
        label=""
        name={title}
        onChange={onChange}
        value={id}
      />
    </Left>
    <Middle>
      <Title>{title}</Title>
      <Previews>
        {previews.map((preview) => (
          <Preview key={preview.url}>
            {preview.image ? (
              <img
                alt={serviceNameLabels[preview.serviceName]}
                src={preview.image}
              />
            ) : null}
            <ServiceName as="a" href={preview.url} target="__blank">
              {serviceNameLabels[preview.serviceName]}
            </ServiceName>
          </Preview>
        ))}
      </Previews>
      <CollapsibleTextArea
        id={`${id}-additional-instructions`}
        label="Additional Instructions"
        name="additionalInstructions"
        rows={5}
        textAreaRef={textAreaRef}
      />
    </Middle>
    <Actions>
      {onGenerate ? (
        <Button
          disabled={isGenerateLoading}
          label="Generate"
          loading={isGenerateLoading}
          onClick={onGenerate}
          secondary
        />
      ) : null}
      <Button label="Edit" onClick={onEdit} outlined />
      <Button
        disabled={isPublishLoading}
        label="Publish"
        loading={isPublishLoading}
        onClick={onPublish}
      />
    </Actions>
  </Wrapper>
)

export default Draft
