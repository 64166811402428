import gql from "graphql-tag"

const image = gql`
  fragment ImageFormat on ImageFormat {
    ext
    hash
    height
    mime
    name
    path
    size
    url
    width
  }

  fragment ImageFormats on ImageFormats {
    large {
      ...ImageFormat
    }
    medium {
      ...ImageFormat
    }
    small {
      ...ImageFormat
    }
    thumbnail {
      ...ImageFormat
    }
    xlarge {
      ...ImageFormat
    }
    xsmall {
      ...ImageFormat
    }
  }

  fragment Image on Image {
    alternativeText
    caption
    ext
    formats {
      ...ImageFormats
    }
    hash
    height
    id
    mime
    name
    provider
    related
    size
    url
    width
  }
`

export default image
