import SOCIALS_AI_INSTRUCTION_ADD from "./socialsAiInstructionAdd"
import SOCIALS_AI_INSTRUCTION_DELETE from "./socialsAiInstructionDelete"
import SOCIALS_AI_INSTRUCTION_UPDATE from "./socialsAiInstructionUpdate"
import SOCIALS_AUTHOR_ADD from "./socialsAuthorAdd"
import SOCIALS_GENERATE_POSTS from "./socialsGeneratePosts"
import SOCIALS_PLATFORM_ADD from "./socialsPlatformAdd"
import SOCIALS_PLATFORM_GATHER_POSTS from "./socialsPlatformGatherPosts"
import SOCIALS_POSTS_ADD from "./socialsPostsAdd"

export default {
  SOCIALS_AI_INSTRUCTION_ADD,
  SOCIALS_AI_INSTRUCTION_DELETE,
  SOCIALS_AI_INSTRUCTION_UPDATE,
  SOCIALS_AUTHOR_ADD,
  SOCIALS_GENERATE_POSTS,
  SOCIALS_PLATFORM_ADD,
  SOCIALS_PLATFORM_GATHER_POSTS,
  SOCIALS_POSTS_ADD
}
