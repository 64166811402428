import { gql } from "graphql-tag"

const authenticate = gql`
  input LoginInput {
    password: String!
    username: String!
  }

  type LoginResponse {
    token: String
  }

  type User {
    firstName: String!
    isAdmin: Boolean!
    isEditor: Boolean!
    isSocials: Boolean!
    username: String!
  }

  type UsersCount {
    active: Int!
    pending: Int!
    total: Int!
  }

  type NewUsersByDateRange {
    date: String!
    newUsers: Int!
  }
`

export default authenticate
