import SocialLinks from "./SocialLinks"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import { useMemo } from "react"

export interface Props {
  blueSky?: string
  className?: string
  facebook?: string
  instagram?: string
  isBig?: boolean
  isShowLabels?: boolean
  reddit?: string
  redditSubreddit?: string
  threads?: string
  twitterX?: string
  website?: string
  youtube?: string
}

export interface ComponentProps extends Props {
  hasSocialMedia?: boolean
}

const usePrepareComponent = (props: Props): ComponentProps => {
  const hasSocialMedia = useMemo(
    () =>
      Boolean(
        props.blueSky ||
          props.facebook ||
          props.instagram ||
          props.reddit ||
          props.redditSubreddit ||
          props.threads ||
          props.twitterX ||
          props.youtube
      ),
    [
      props.blueSky,
      props.facebook,
      props.instagram,
      props.reddit,
      props.redditSubreddit,
      props.threads,
      props.twitterX,
      props.youtube
    ]
  )

  return {
    ...props,
    hasSocialMedia
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  SocialLinks
)
