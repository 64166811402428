import { gql } from "graphql-tag"

const socials = gql`
  type Mutation {
    socialsAiInstructionAdd(
      socialsAiInstructionAddInput: SocialsAiInstructionAddInput!
    ): SocialsAiInstructionAddResponse!

    socialsAiInstructionDelete(id: ID!): SocialsAiInstructionDeleteResponse!

    socialsAiInstructionUpdate(
      socialsAiInstructionUpdateInput: SocialsAiInstructionUpdateInput!
    ): SocialsAiInstructionUpdateResponse!

    socialsAuthorAdd(
      socialsAuthorAddInput: SocialsAuthorAddInput!
    ): SocialsAuthorAddResponse!

    socialsGeneratePosts(
      socialsGeneratePostsInput: SocialsGeneratePostsInput!
    ): SocialsGeneratePostsResponse!

    socialsPlatformAdd(
      socialsPlatformAddInput: SocialsPlatformAddInput!
    ): SocialsPlatform!

    socialsPlatformGatherPosts(
      socialsPlatformGatherPostsInput: SocialsPlatformGatherPostsInput!
    ): SocialsPlatformGatherPostsResponse!

    socialsPostsAdd(
      socialsPostsAddInput: SocialsPostsAddInput!
    ): SocialsPostsAddResponse!
  }
`

export default socials
