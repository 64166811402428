import contentAuthor from "./contentAuthor"
import gql from "graphql-tag"
import image from "./image"
import newsSource from "./newsSource"

const news = gql`
  ${contentAuthor}
  ${image}
  ${newsSource}

  fragment News on News {
    author {
      ...ContentAuthor
    }
    avForums {
      ...NewsSource
    }
    avNirvana {
      ...NewsSource
    }
    avsForum {
      ...NewsSource
    }
    bluRay {
      ...NewsSource
    }
    bluRayAuthority {
      ...NewsSource
    }
    digitalBits {
      ...NewsSource
    }
    doBlu {
      ...NewsSource
    }
    hidefDigest {
      ...NewsSource
    }
    homeTheaterForum {
      ...NewsSource
    }
    id
    image {
      ...Image
    }
    isPublished
    name
    publishedAt
    slug
    subheading
    theaterByte {
      ...NewsSource
    }
    text
    whySoBlu {
      ...NewsSource
    }
  }
`

export default news
