import gql from "graphql-tag"

const EIGHT_LEGGED_FREAK_REVIEW_UPDATE = gql`
  mutation eightLeggedFreakReviewUpdate(
    $eightLeggedFreakReviewUpdateInput: EightLeggedFreakReviewUpdateInput!
    $serviceName: String!
  ) {
    eightLeggedFreakReviewUpdate(
      eightLeggedFreakReviewUpdateInput: $eightLeggedFreakReviewUpdateInput
      serviceName: $serviceName
    ) {
      id
    }
  }
`
export default EIGHT_LEGGED_FREAK_REVIEW_UPDATE
