import Button from "components/buttons/Button"
import Reset from "components/Reset"
import colors from "styles/colors"
import { rgba } from "polished"
import styled from "styled-components"

const BUTTONS_ALPHA = 0.4
const BUTTONS_ALPHA_HOVER = 0.3

export const CarouselContainer = styled.section`
  max-width: 100%;
  overflow: hidden;
  position: relative;
`

export const CarouselInnerUl = styled(Reset.Ul)<{ $translateX: number }>`
  display: flex;
  flex-wrap: nowrap;
  transform: ${({ $translateX }) => `translateX(${$translateX}%)`};
  transition: transform 0.3s ease-out;
  width: 100%;
`

export const CarouselSlideLi = styled(Reset.Li)<{ $slidesToShow: number }>`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  width: ${({ $slidesToShow }) => `calc(100% / ${$slidesToShow})`};
`

const NavButton = styled(Button)`
  background-color: ${rgba(colors.black, BUTTONS_ALPHA)};
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    background-color: ${rgba(colors.black, BUTTONS_ALPHA_HOVER)};
  }
`

export const NextButton = styled(NavButton)`
  right: 6px;
`

export const PrevButton = styled(NavButton)`
  left: 6px;
`
