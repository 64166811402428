/* eslint-disable max-lines-per-function, max-statements, max-lines */
import { mutations, queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import GeneratePosts from "./GeneratePosts";
import prepareComponent from "@bluframe/grapple/prepareComponent";
const { socials: { SOCIALS_GENERATE_POSTS } } = mutations;
const { homeVideoReviews: { GET_HOME_VIDEO_REVIEW, SEARCH_HOME_VIDEO_REVIEWS }, news: { GET_NEWS, SEARCH_NEWS }, socials: { GET_SOCIALS_PLATFORMS } } = queries;
export const usePrepareComponent = () => {
    const [platforms, setPlatforms] = useState({});
    const [generatedPosts, setGeneratedPosts] = useState();
    const { data: getSocialsPlatformsData, loading: getSocialsPlatformsLoading } = useQuery(GET_SOCIALS_PLATFORMS, {
        onCompleted: (data) => {
            const newPlatforms = data.getSocialsPlatforms.reduce((acc, platform) => ({
                ...acc,
                [platform.serviceName]: true
            }), {});
            setPlatforms(newPlatforms);
        }
    });
    const [searchHomeVideoReviews, { data: searchHomeVideoReviewsData, loading: searchHomeVideoReviewsLoading }] = useLazyQuery(SEARCH_HOME_VIDEO_REVIEWS);
    const [searchNews, { data: searchNewsData, loading: searchNewsLoading }] = useLazyQuery(SEARCH_NEWS);
    const [socialsGeneratePosts, { loading: socialsGeneratePostsLoading }] = useMutation(SOCIALS_GENERATE_POSTS, {
        // Generate posts by creating a map of platformName to post
        onCompleted: (data) => {
            setGeneratedPosts((prevGeneratedPosts) => ({
                ...prevGeneratedPosts,
                ...(data?.socialsGeneratePosts.posts ?? []).reduce((acc, post) => ({ ...acc, [post.platformName]: post }), {})
            }));
        }
    });
    const [selectedHomeVideoReviewId, setSelectedHomeVideoReviewId] = useState("");
    const [selectedNewsId, setSelectedNewsId] = useState("");
    const onGetHomeVideoReviewsSuggestions = ({ inputValue }) => {
        searchHomeVideoReviews({
            variables: {
                searchHomeVideoReviewsInput: {
                    title: inputValue
                }
            }
        });
    };
    const onGetNewsSuggestions = ({ inputValue }) => {
        searchNews({
            variables: {
                searchNewsInput: {
                    name: inputValue
                }
            }
        });
    };
    const { data: getHomeVideoReviewData, loading: getHomeVideoReviewDataLoading } = useQuery(GET_HOME_VIDEO_REVIEW, {
        skip: !selectedHomeVideoReviewId,
        variables: {
            id: selectedHomeVideoReviewId
        }
    });
    const { data: getNewsData, loading: getNewsDataLoading } = useQuery(GET_NEWS, {
        skip: !selectedNewsId,
        variables: {
            id: selectedNewsId
        }
    });
    /**
     * Generate a text based on the selected home video review
     * or news item
     */
    const socialsGeneratePostsInputText = useMemo(() => {
        /** Generate a text based on the selected home video review
         * This includes the name, video, audio, extra, and total summaries
         */
        if (selectedHomeVideoReviewId &&
            getHomeVideoReviewData?.getHomeVideoReview) {
            const { name, summary } = getHomeVideoReviewData.getHomeVideoReview;
            return `
# Review
${name}

# Video
${summary.video.long}
# Audio
${summary.audio.long}
# Extras
${summary.extra.long}

# Total
${summary.total.long}
`;
        }
        /** Generate a text based on the selected news item
         * This includes the name, subheading, and text
         */
        if (selectedNewsId && getNewsData?.getNews) {
            const { name, subheading, text } = getNewsData.getNews;
            return `
# News
${name}
${subheading}

${text}
`;
        }
        return "";
    }, [
        selectedHomeVideoReviewId,
        selectedNewsId,
        getHomeVideoReviewData,
        getNewsData
    ]);
    const onClearAllClick = () => {
        setGeneratedPosts(undefined);
    };
    const onClearClick = (platformName) => () => {
        setGeneratedPosts((prevGeneratedPosts) => {
            const newGeneratedPosts = { ...prevGeneratedPosts };
            delete newGeneratedPosts[platformName];
            return newGeneratedPosts;
        });
    };
    const onGenerateAllClick = (additionalInstructions) => {
        socialsGeneratePosts({
            variables: {
                socialsGeneratePostsInput: {
                    additionalInstructions,
                    // Only include platforms that are checked
                    platformNames: Object.entries(platforms)
                        .filter(([, value]) => value)
                        .map(([key]) => key),
                    text: socialsGeneratePostsInputText
                }
            }
        });
    };
    const onGenerateClick = (platformName) => () => {
        socialsGeneratePosts({
            variables: {
                socialsGeneratePostsInput: {
                    platformNames: [platformName],
                    text: socialsGeneratePostsInputText
                }
            }
        });
    };
    const onSelectHomeVideoReviewId = (id) => {
        setSelectedNewsId("");
        setSelectedHomeVideoReviewId(id);
    };
    const onSelectNewsId = (id) => {
        setSelectedHomeVideoReviewId("");
        setSelectedNewsId(id);
    };
    const isGenerateDisabled = !socialsGeneratePostsInputText;
    // Prepare a function to handle platform checkbox change
    const onChangePlatformCheckbox = (platformName) => {
        setPlatforms((prevPlatforms) => ({
            ...prevPlatforms,
            [platformName]: !prevPlatforms[platformName]
        }));
    };
    // Prepare platforms checkboxes based on query data
    const platformsCheckboxes = getSocialsPlatformsData?.getSocialsPlatforms.map((item) => ({
        inputId: item.serviceName,
        isChecked: platforms[item.serviceName] ?? false,
        label: item.name,
        name: item.serviceName,
        onChange: onChangePlatformCheckbox,
        value: item.serviceName
    })) ?? [];
    const socialsPlatforms = (getSocialsPlatformsData?.getSocialsPlatforms ?? []).filter((item) => {
        const checkbox = platformsCheckboxes.find((checkboxItem) => checkboxItem.value === item.serviceName);
        return checkbox?.isChecked;
    });
    return {
        arePlatformsLoading: getSocialsPlatformsLoading,
        generatedPosts,
        homeVideoReviewTitles: searchHomeVideoReviewsData?.searchHomeVideoReviews ?? [],
        isAutosuggestLoading: searchHomeVideoReviewsLoading || searchNewsLoading,
        isGenerateDisabled,
        isGenerateLoading: socialsGeneratePostsLoading,
        newsTitles: searchNewsData?.searchNews ?? [],
        onClearAllClick,
        onClearClick,
        onGenerateAllClick,
        onGenerateClick,
        onGetHomeVideoReviewsSuggestions,
        onGetNewsSuggestions,
        onSelectHomeVideoReviewId,
        onSelectNewsId,
        platformsCheckboxes,
        selectedHomeVideoReviewId,
        selectedNewsId,
        socialsPlatforms
    };
};
export default prepareComponent(usePrepareComponent)(GeneratePosts);
