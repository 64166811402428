import blog from "./blog"
import critics from "./critics"
import eightLeggedFreak from "./eightLeggedFreak"
import forum from "./forum"
import homeVideoReviews from "./homeVideoReviews"
import news from "./news"
import socials from "./socials"

export default {
  blog,
  critics,
  eightLeggedFreak,
  forum,
  homeVideoReviews,
  news,
  socials
}
