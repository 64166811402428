/* eslint-disable no-ternary */

import { Theme } from "@bluframe/blublocks"
import Typography from "components/Typography"
import colors from "styles/colors"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

export const Wrapper = styled.div<{ $hasImage: boolean }>`
  background: ${({ theme }: { theme: Theme }) => theme.palette.text.contrast};
  column-gap: 10px;
  display: grid;

  ${({ $hasImage }) => ($hasImage ? "grid-template-columns: 30px 1fr;" : "")}
  padding: 10px 20px;
  transition: 0.3s;

  &:hover {
    background: ${colors.alabaster};

    ${Typography.H3} {
      color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
    }
  }

  ${mediaQueries.tablet} {
    column-gap: 20px;
    ${({ $hasImage }) => ($hasImage ? "grid-template-columns: 40px 1fr;" : "")}
  }
`

export const Li = styled.li`
  cursor: pointer;
  list-style-type: none;
`

export const SuggestionPadding = styled.div`
  cursor: pointer;
  display: grid;
  justify-items: center;
  padding: 10px 20px;
`

export const Name = styled(Typography.H3)`
  font-size: 16px;
`
