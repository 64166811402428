import { SubtitleH3 as BluBlocksSubtitleH3 } from "@bluframe/blublocks"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

const SubtitleH3 = styled(BluBlocksSubtitleH3)`
  font-size: 14px;
  font-weight: 700;
  margin: 0;

  ${mediaQueries.tablet} {
    font-size: 16px;
  }
`

export default SubtitleH3
