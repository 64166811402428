/* eslint-disable no-ternary, react/jsx-indent, max-lines-per-function, no-nested-ternary */

import { List, Wrapper } from "./styled"
import { ComponentProps } from "."
import { HomeVideoReviewSearchItem } from "@bluframe/mightychroma-mightytool-frontend-types"
import { InputText } from "@bluframe/blublocks"

import Suggestion from "./Suggestion"

const Autosuggest = ({
  disabled,
  getItemProps,
  getMenuProps,
  inputProps: { id, ref, ...inputProps },
  isLoading,
  isOpen,
  label,
  onKeyDown,
  onSuggestionClick,
  suggestions
}: ComponentProps) => (
  <Wrapper>
    <InputText
      disabled={disabled}
      inputId={id}
      inputProps={inputProps}
      label={label}
      name="search"
      onKeyDown={onKeyDown}
      ref={ref}
      value={inputProps.value}
    />
    <List {...getMenuProps()}>
      {isOpen ? (
        isLoading ? (
          <Suggestion
            ariaLabel="Loading"
            isLoading
            itemProps={{
              "aria-disabled": false,
              "aria-selected": false,
              id: "loading",
              role: "option"
            }}
          />
        ) : (
          suggestions.map((suggestion, index) => {
            let { name } = suggestion

            if ((suggestion as HomeVideoReviewSearchItem).mas?.year) {
              name += ` (${
                (suggestion as HomeVideoReviewSearchItem).mas?.year
              })`
            }

            return (
              <Suggestion
                ariaLabel={name}
                id={suggestion.id}
                itemProps={getItemProps({ index, item: suggestion })}
                key={suggestion.id}
                name={name}
                onSuggestionClick={onSuggestionClick}
              />
            )
          })
        )
      ) : null}
    </List>
  </Wrapper>
)

export default Autosuggest
