import CollapsibleTextArea from "./CollapsibleTextArea"
import { RefObject } from "react"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import useToggle from "@bluframe/grapple/useToggle"

export interface Props {
  id?: string
  label: string
  name: string
  placeholder?: string
  rows?: number
  textAreaRef?: RefObject<HTMLTextAreaElement>
}

export interface ComponentProps extends Props {
  isShow: boolean
  onToggle: () => void
}

const usePrepareComponent = (props: Props): ComponentProps => {
  const [isShow, toggle] = useToggle()

  const onToggle = () => {
    toggle()
  }

  return {
    ...props,
    isShow,
    onToggle
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  CollapsibleTextArea
)
