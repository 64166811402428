import { gql } from "graphql-tag"

const critics = gql`
  input ReviewAddInput {
    isOverridesOnly: Boolean
    isUHD: Boolean!
    overrides: ReviewOverridesInput
    serviceName: String!
    url: String!
  }

  input ReviewUpdateInput {
    isOverridesOnly: Boolean
    isUHD: Boolean!
    overrides: ReviewOverridesInput
    serviceName: String!
    url: String!
  }

  type Review {
    audio: ScoreAndQuote
    author: Author
    editions: [String]
    extra: ScoreAndQuote
    id: ID!
    images: HomeVideoImages
    isFlagged: Boolean!
    movie: ScoreAndQuote
    releaseDate: String
    total: ScoreAndQuote
    url: String!
    video: ScoreAndQuote
  }

  input ReviewOverridesInput {
    audio: ScoreAndQuoteInputOverrides
    extra: ScoreAndQuoteInputOverrides
    movie: ScoreAndQuoteInputOverrides
    releaseDate: String
    total: ScoreAndQuoteInputOverrides
    video: ScoreAndQuoteInputOverrides
  }

  type ReviewResponse {
    id: ID!
    serviceName: String!
  }

  input PreviewAddInput {
    isUHD: Boolean!
    overrides: ReviewOverridesInput
    serviceName: String!
    url: String!
  }

  type Preview {
    audio: ScoreAndQuote
    author: Author
    editions: [String]
    extra: ScoreAndQuote
    errorFields: [String]
    images: HomeVideoImages
    isValid: Boolean!
    movie: ScoreAndQuote
    releaseDate: String
    serviceName: String!
    tags: [String]
    total: ScoreAndQuote
    url: String!
    video: ScoreAndQuote
  }

  type PreviewValidateResponse {
    errorFields: [String]
    isValid: Boolean!
    serviceName: String!
  }
`

export default critics
