import { H4, Paragraph, Theme } from "@bluframe/blublocks"
import mediaQueries from "styles/mediaQueries"
import { rgba } from "polished"
import styled from "styled-components"

const ALPHA = 0.1

export const Wrapper = styled.div`
  align-items: center;
  background: ${({ theme }: { theme: Theme }) => theme.palette.text.contrast};
  border: 1px solid
    ${({ theme }: { theme: Theme }) => rgba(theme.palette.text.main, ALPHA)};
  border-radius: 4px;
  column-gap: 20px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 20px;
`

export const Title = styled(H4)`
  font-size: 18px;
`

export const Left = styled.div`
  align-items: start;
  display: grid;
`

export const Middle = styled.div`
  align-items: center;
  display: grid;
  row-gap: 10px;
`

export const Previews = styled.div`
  column-gap: 10px;
  display: grid;

  ${mediaQueries.tablet} {
    grid-template-columns: repeat(7, minmax(60px, 100px));
  }

  ${mediaQueries.desktop} {
    grid-template-columns: repeat(7, minmax(100px, 100px));
  }
`

export const Preview = styled.div`
  display: grid;
  overflow: hidden;
`

export const ServiceName = styled(Paragraph)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.subtitle.main};
`

export const Actions = styled.div`
  align-items: center;
  column-gap: 10px;
  display: grid;
  grid-auto-flow: column;
`
