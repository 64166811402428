import gql from "graphql-tag"

const EIGHT_LEGGED_FREAK_NEWS_SOURCE_UPDATE = gql`
  mutation eightLeggedFreakNewsSourceUpdate(
    $eightLeggedFreakNewsSourceUpdateInput: EightLeggedFreakNewsSourceUpdateInput!
    $serviceName: String!
  ) {
    eightLeggedFreakNewsSourceUpdate(
      eightLeggedFreakNewsSourceUpdateInput: $eightLeggedFreakNewsSourceUpdateInput
      serviceName: $serviceName
    ) {
      id
    }
  }
`
export default EIGHT_LEGGED_FREAK_NEWS_SOURCE_UPDATE
