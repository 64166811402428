import gql from "graphql-tag"

const UPDATE_NEWS = gql`
  mutation updateNews(
    $newsUpdateInput: NewsUpdateInput!
    $options: NewsUpdateInputOptions
  ) {
    updateNews(newsUpdateInput: $newsUpdateInput, options: $options) {
      id
    }
  }
`
export default UPDATE_NEWS
