/* eslint-disable no-ternary, max-statements, max-lines-per-function */

import { FormEventHandler, RefObject, useRef, useState } from "react"
import {
  News,
  NewsUpdateInput
} from "@bluframe/mightychroma-mightytool-frontend-types"
import Item from "./Item"
import prepareComponent from "@bluframe/grapple/prepareComponent"
import slugify from "@sindresorhus/slugify"

type ModalContent =
  | "deleteConfirm"
  | "generateConfirm"
  | "publishConfirm"
  | null

export interface Props {
  isAdd?: boolean
  isEditing?: boolean
  isGenerateLoading: boolean
  isPublishLoading: boolean
  isUpdateLoading: boolean
  item?: News
  onCancel: () => void
  onDelete: () => void
  onEdit: () => void
  // eslint-disable-next-line no-unused-vars
  onGenerate: (additionalInstructions?: string) => void
  onPublishToggle: () => void
  // eslint-disable-next-line no-unused-vars
  onSave: (contest: Omit<NewsUpdateInput, "id">) => void
  // Props
}

export interface ComponentProps
  extends Omit<
    Props,
    | "isEditing"
    | "onCancel"
    | "onDelete"
    | "onEdit"
    | "onGenerate"
    | "onPublishToggle"
    | "onSave"
  > {
  actionButtonLabel: string
  actionButtonOnClick?: () => void
  additionalInstructionsRef: RefObject<HTMLTextAreaElement>
  areActionsDisabled: boolean
  isEditMode: boolean
  isModalOpen: boolean
  modalContent: ModalContent
  imageIdRef: RefObject<HTMLInputElement>
  nameRef: RefObject<HTMLInputElement>
  onGenerateSlug: () => void
  onModalClose: () => void
  // eslint-disable-next-line no-unused-vars
  onModalConfirmOpen: (modalContent: ModalContent) => () => void
  onModalDeleteConfirm: () => void
  onModalGenerateConfirm: () => void
  onModalPublishToggleConfirm: () => void
  onSubmit: FormEventHandler
  outlinedButtonLabel: string
  outlinedButtonOnClick?: () => void
  slugRef: RefObject<HTMLInputElement>
  subheadingRef: RefObject<HTMLTextAreaElement>
  textRef: RefObject<HTMLTextAreaElement>
}

const DEFAULT_MODAL_CONTENT = null

export const usePrepareComponent = ({
  isEditing,
  onCancel,
  onDelete,
  onEdit,
  onGenerate,
  onPublishToggle,
  onSave,
  ...props
}: Props): ComponentProps => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState<ModalContent>(
    DEFAULT_MODAL_CONTENT
  )

  const additionalInstructionsRef = useRef<HTMLTextAreaElement>(null)
  const imageIdRef = useRef<HTMLInputElement>(null)
  const nameRef = useRef<HTMLInputElement>(null)
  const slugRef = useRef<HTMLInputElement>(null)
  const subheadingRef = useRef<HTMLTextAreaElement>(null)
  const textRef = useRef<HTMLTextAreaElement>(null)

  const isEditMode = Boolean(props.isAdd || isEditing)

  const onModalClose = () => {
    setModalContent(DEFAULT_MODAL_CONTENT)
    setIsModalOpen(false)
  }

  const onModalConfirmOpen = (newModalContent: ModalContent) => () => {
    setModalContent(newModalContent)
    setIsModalOpen(true)
  }

  const onModalDeleteConfirm = () => {
    onDelete()
    onModalClose()
  }

  const actionButtonLabel = isEditMode ? "Save" : "Delete"
  const outlinedButtonLabel = isEditMode ? "Cancel" : "Edit"

  const actionButtonOnClick = isEditMode
    ? undefined
    : onModalConfirmOpen("deleteConfirm")
  const outlinedButtonOnClick = isEditMode ? onCancel : onEdit

  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault()

    if (!nameRef.current) {
      return
    }

    onSave({
      imageId: imageIdRef.current?.value
        ? Number(imageIdRef.current?.value)
        : undefined,
      name: nameRef.current.value ?? "",
      slug: slugRef.current?.value ?? "",
      subheading: subheadingRef.current?.value,
      text: textRef.current?.value ?? ""
    })
  }

  const onModalGenerateConfirm = () => {
    onGenerate(
      additionalInstructionsRef.current?.value === ""
        ? undefined
        : additionalInstructionsRef.current?.value
    )
    onModalClose()
  }

  const onModalPublishToggleConfirm = () => {
    onPublishToggle()
    onModalClose()
  }

  const onGenerateSlug = () => {
    if (!nameRef.current || !slugRef.current) {
      return
    }
    slugRef.current.value = slugify(nameRef.current.value)
  }

  const areActionsDisabled =
    props.isGenerateLoading || props.isPublishLoading || props.isUpdateLoading

  return {
    ...props,
    actionButtonLabel,
    actionButtonOnClick,
    additionalInstructionsRef,
    areActionsDisabled,
    imageIdRef,
    isEditMode,
    isModalOpen,
    modalContent,
    nameRef,
    onGenerateSlug,
    onModalClose,
    onModalConfirmOpen,
    onModalDeleteConfirm,
    onModalGenerateConfirm,
    onModalPublishToggleConfirm,
    onSubmit,
    outlinedButtonLabel,
    outlinedButtonOnClick,
    slugRef,
    subheadingRef,
    textRef
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Item
)
