import { gql } from "graphql-tag"

const socials = gql`
  type Query {
    getSocialsAiInstructionById(id: ID!): SocialsAiInstruction
    getSocialsAiInstructions(
      socialsAiInstructionsGetInput: SocialsAiInstructionsGetInput
    ): [SocialsAiInstruction]

    getSocialsPlatforms(
      socialsPlatformsGetInput: SocialsPlatformsGetInput
    ): [SocialsPlatform]!
  }
`

export default socials
