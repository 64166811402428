import { Reset, Theme } from "@bluframe/blublocks"
import styled from "styled-components"

export const Wrapper = styled.div`
  background: ${({ theme }: { theme: Theme }) => theme.palette.text.contrast};
  display: grid;
  padding: 10px 30px;
  row-gap: 20px;
`

export const Ul = styled(Reset.Ul)`
  display: grid;
  row-gap: 20px;
`
