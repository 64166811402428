import { IconProps } from "@bluframe/blublocks"
import Item from "./Item"
import { Props as SubItemProps } from "./SubItem"

interface SubItem extends Omit<SubItemProps, "isExpanded"> {
  isAdmin?: boolean
  isEditor?: boolean
  isSocials?: boolean
}

export interface Props {
  iconGroup?: IconProps["group"]
  iconName: IconProps["name"]
  isAdmin: boolean
  isEditor: boolean
  isExpanded: boolean
  isShowSubItems: boolean
  isSocials: boolean
  label: string
  link?: string
  onSetOpenSubItems: () => void
  subItems?: SubItem[]
}

export interface ComponentProps extends Props {}

export default Item
