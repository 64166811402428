/* eslint-disable no-ternary, max-lines-per-function */

import {
  Actions,
  Buttons,
  PlatformsCheckboxesUl,
  PostAndActions,
  PostsUl,
  Wrapper
} from "./styled"
import { Checkbox, Loading, TextArea } from "@bluframe/blublocks"
import Autosuggest from "components/Autosuggest"
import Button from "components/buttons/Button"
import CollapsibleTextArea from "components/inputs/CollapsibleTextArea"
import { ComponentProps } from "."
import Select from "components/inputs/Select"

const GeneratePosts = ({
  arePlatformsLoading,
  generatedPostsCounter,
  generatedPosts,
  homeVideoReviewTitles,
  isAutosuggestLoading,
  isGenerateDisabled,
  isGenerateLoading,
  newsTitles,
  onChangeType,
  onClearAllClick,
  onClearClick,
  onGenerateAllClick,
  onGenerateClick,
  onGetHomeVideoReviewsSuggestions,
  onGetNewsSuggestions,
  onSelectHomeVideoReviewId,
  onSelectNewsId,
  platformsCheckboxes,
  selectedHomeVideoReviewId,
  selectedNewsId,
  selectedType,
  socialsPlatforms,
  textAreaRef,
  types
}: ComponentProps) => (
  <Wrapper>
    <Select
      label="Type"
      name="type"
      onChange={onChangeType}
      options={types}
      placeholder="Select News and Home Video Reviews"
      value={selectedType}
    />

    <Actions>
      {arePlatformsLoading ? (
        <Loading size={24} />
      ) : (
        <PlatformsCheckboxesUl aria-label="Platforms">
          {platformsCheckboxes.map((item) => (
            <li aria-label={`${item.label} Platform`} key={item.name}>
              <Checkbox {...item} />
            </li>
          ))}
        </PlatformsCheckboxesUl>
      )}
      <Buttons>
        <Button label="Clear All" onClick={onClearAllClick} secondary />
        <Button
          disabled={isGenerateDisabled || isGenerateLoading}
          label="Generate All"
          loading={isGenerateLoading}
          onClick={onGenerateAllClick}
        />
      </Buttons>
    </Actions>

    {selectedType === "homeVideoReviews" ? (
      <Autosuggest
        isLoading={isAutosuggestLoading}
        label="Select a Home Video Review"
        onGetSuggestions={onGetHomeVideoReviewsSuggestions}
        onSuggestionClick={onSelectHomeVideoReviewId}
        suggestions={homeVideoReviewTitles}
        value={selectedHomeVideoReviewId}
      />
    ) : null}
    {selectedType === "news" ? (
      <Autosuggest
        label="Select a News"
        onGetSuggestions={onGetNewsSuggestions}
        onSuggestionClick={onSelectNewsId}
        suggestions={newsTitles}
        value={selectedNewsId}
      />
    ) : null}

    <CollapsibleTextArea
      id="all-additional-instructions"
      label="Additional Instructions"
      name="additionalInstructions"
      rows={5}
      textAreaRef={textAreaRef}
    />

    <PostsUl aria-label="Posts">
      {socialsPlatforms.map((platform) => (
        <li
          aria-label={`${platform.name} Post`}
          key={`${platform.serviceName}-${generatedPostsCounter}`}
        >
          <PostAndActions>
            <TextArea
              defaultValue={generatedPosts?.[platform.serviceName]?.text}
              isExpanded
              label={platform.name}
              maxChars={platform.maxCharactersPerPost}
              name={platform.serviceName}
              placeholder={`Post for ${platform.name}`}
              rows={10}
            />
            <Buttons>
              <Button
                label="Clear"
                onClick={onClearClick(platform.serviceName)}
                secondary
              />
              <Button
                disabled={isGenerateDisabled || isGenerateLoading}
                label="Generate"
                loading={isGenerateLoading}
                onClick={onGenerateClick(platform.serviceName)}
              />
            </Buttons>
          </PostAndActions>
        </li>
      ))}
    </PostsUl>
  </Wrapper>
)

export default GeneratePosts
