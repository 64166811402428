import { Reset } from "@bluframe/blublocks"
import styled from "styled-components"

export const Wrapper = styled.div`
  display: grid;
  row-gap: 20px;
`

export const Actions = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 20px;
`

export const PlatformsCheckboxesUl = styled(Reset.Ul)`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  column-gap: 20px;
`

export const PostsUl = styled(Reset.Ul)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 30px;
`

export const PostAndActions = styled.div`
  display: grid;
  justify-items: end;
  row-gap: 20px;

  & textarea {
    margin: 0;
  }
`

export const Buttons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 20px;
`
