/* eslint-disable no-ternary */
/* eslint-disable max-lines-per-function, max-statements */
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import Add from "./Add";
import { mutations } from "@bluframe/mightychroma-mightytool-graphql-schema";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useCreateReviews from "hooks/useCreateReviews";
import { useMutation } from "@apollo/client";
import usePreviews from "hooks/usePreviews";
import useReviews from "hooks/useReviews";
import useUser from "hooks/useUser";
const { critics: { VALIDATE_PREVIEW } } = mutations;
const DEFAULT_IS_COMPACT = true;
const usePrepareComponent = () => {
    const { removeImage } = usePreviews();
    const { clear, setIsMatchTitle, setIsUHD, setOverrides, setSelectedIsPreviewLoading, setSelectedIsPreviewValid, state } = useReviews();
    const { isAdmin } = useUser();
    const [isCompact, setIsCompact] = useState(DEFAULT_IS_COMPACT);
    const [validatePreview] = useMutation(VALIDATE_PREVIEW);
    useEffect(() => {
        if (!isAdmin && state.isUHD !== false) {
            setIsUHD(false);
        }
    }, [isAdmin, state.isUHD, setIsUHD]);
    const { areReviewsLoading } = useCreateReviews();
    const onClear = useCallback((serviceName) => () => {
        // Remove image
        removeImage(serviceName);
        // Clear review
        clear(serviceName);
    }, [clear, removeImage]);
    const onSetOverrides = useCallback((serviceName) => (overrides) => {
        setOverrides(overrides, serviceName);
        setSelectedIsPreviewLoading(true, serviceName);
        validatePreview({
            onCompleted: (data) => {
                setSelectedIsPreviewLoading(false, serviceName);
                setSelectedIsPreviewValid(data.validatePreview.isValid, data.validatePreview.serviceName);
            },
            variables: {
                previewValidateInput: {
                    isUHD: state.isUHD,
                    overrides,
                    serviceName,
                    url: state[serviceName].selected.url
                }
            }
        });
    }, [
        validatePreview,
        setOverrides,
        setSelectedIsPreviewLoading,
        setSelectedIsPreviewValid,
        state
    ]);
    const reviewsControlCheckboxesProps = useMemo(() => {
        const isMatchTitleCheckboxProps = {
            checked: state.isMatchTitle,
            isDisabled: areReviewsLoading,
            onChange: () => {
                setIsMatchTitle(!state.isMatchTitle);
            }
        };
        const isUHDCheckboxProps = {
            checked: state.isUHD,
            isDisabled: areReviewsLoading,
            onChange: () => {
                setIsUHD(!state.isUHD);
            }
        };
        const isCompactCheckboxProps = {
            checked: isCompact,
            isDisabled: areReviewsLoading,
            onChange: () => {
                setIsCompact(!isCompact);
            }
        };
        return {
            isAdmin,
            isCompactCheckboxProps,
            isMatchTitleCheckboxProps,
            isUHDCheckboxProps
        };
    }, [
        areReviewsLoading,
        isAdmin,
        state.isMatchTitle,
        setIsMatchTitle,
        state.isUHD,
        setIsUHD,
        isCompact,
        setIsCompact
    ]);
    return {
        areReviewsLoading,
        avForums: state.avForums.selected,
        avNirvana: state.avNirvana.selected,
        avsForum: state.avsForum.selected,
        bluRay: state.bluRay.selected,
        bluRayAuthority: state.bluRayAuthority.selected,
        digitalBits: state.digitalBits.selected,
        doBlu: state.doBlu.selected,
        hidefDigest: state.hidefDigest.selected,
        highDefDiscNews: state.highDefDiscNews.selected,
        homeTheaterForum: state.homeTheaterForum.selected,
        onClear,
        onSetOverrides,
        reviewsControlCheckboxesProps,
        theaterByte: state.theaterByte.selected,
        whySoBlu: state.whySoBlu.selected
    };
};
export default prepareComponent(usePrepareComponent)(memo(Add));
