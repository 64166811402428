import gql from "graphql-tag"

const socialsPlatform = gql`
  fragment SocialsPlatform on SocialsPlatform {
    apiVersion
    createdAt
    description
    id
    maxCharactersPerPost
    name
    serviceName
    targetCharactersPerPost
    updatedAt
    url
  }
`

export default socialsPlatform
