import { Image } from "@bluframe/mightychroma-mightytool-frontend-types"
import { MouseEventHandler } from "react"
import Suggestion from "./Suggestion"
import { UseComboboxGetItemPropsReturnValue } from "downshift"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface Props {
  ariaLabel?: string
  id?: string
  image?: Image
  isLoading?: boolean
  itemProps: UseComboboxGetItemPropsReturnValue
  name?: string
  // eslint-disable-next-line no-unused-vars
  onSuggestionClick?: (id: string, name: string) => void
}

export interface ComponentProps extends Omit<Props, "onSuggestionClick"> {
  onClick: MouseEventHandler
}

export const usePrepareComponent = (props: Props): ComponentProps => {
  const onClick: MouseEventHandler = (event) => {
    // Downshift onClick
    props.itemProps.onClick?.(event)

    // onSuggestionClick
    if (props.id) {
      props.onSuggestionClick?.(props.id, "title")
    }
  }

  return {
    ...props,
    onClick
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Suggestion
)
