/* eslint-disable max-lines-per-function, max-statements */
import { memo, useCallback, useEffect, useMemo } from "react";
import { mutations, queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useLazyQuery, useMutation } from "@apollo/client";
import Reviews from "./Reviews";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useDeepCompareMemoize from "helpers/useDeepCompareMemoize";
import useEightLeggedFreakList from "hooks/useEightLeggedFreakList";
import usePreviews from "hooks/usePreviews";
import usePrevious from "@bluframe/grapple/usePrevious";
import useReviews from "hooks/useReviews";
import useUser from "hooks/useUser";
const { critics: { ADD_PREVIEW }, eightLeggedFreak: { EIGHT_LEGGED_FREAK_REVIEW_UPDATE } } = mutations;
const { GET_OMDB_ID } = queries;
const ZERO = 0;
const THREE = 3;
const usePrepareComponent = ({ actions, name, serviceName, setOMDbId }) => {
    const { addImage } = usePreviews();
    const { setIsToggleFlaggedLoading, setOverrides, setSelectedId, setSelectedIsPreviewLoading, setSelectedIsPreviewValid, state } = useReviews();
    const { isAdmin } = useUser();
    const serviceState = useMemo(() => state[serviceName], [state, serviceName]);
    const previousServiceState = usePrevious(serviceState);
    const [addPreview] = useMutation(ADD_PREVIEW, {
        onCompleted: (data) => {
            setSelectedIsPreviewLoading(false, serviceName);
            setSelectedIsPreviewValid(data.addPreview.isValid, serviceName);
            if (data.addPreview.images?.front) {
                // Add image to image selector
                addImage({
                    serviceName,
                    url: data.addPreview.images.front
                });
            }
            setOverrides({ releaseDate: data.addPreview.releaseDate }, serviceName);
        }
    });
    const [getOmdbId] = useLazyQuery(GET_OMDB_ID, {
        onCompleted: (omdbIdData) => {
            if (setOMDbId && omdbIdData?.omdbId) {
                setOMDbId(omdbIdData.omdbId);
            }
        }
    });
    const { refetch } = useEightLeggedFreakList(serviceName);
    const [eightLeggedFreakReviewUpdate, { loading: flagLoading }] = useMutation(EIGHT_LEGGED_FREAK_REVIEW_UPDATE);
    useEffect(() => {
        if (flagLoading !== state.isToggleFlaggedLoading) {
            setIsToggleFlaggedLoading(flagLoading);
        }
        // It just goes into infinite loop if we include
        // setIsToggleFlaggedLoading in the dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flagLoading, state.isToggleFlaggedLoading]);
    useEffect(() => {
        if (serviceState.selected.url &&
            serviceState.selected.url !== previousServiceState?.selected.url &&
            !serviceState.selected.isCreated &&
            !serviceState.selected.isPreviewLoading &&
            !serviceState.selected.isPreviewValid) {
            setSelectedIsPreviewLoading(true, serviceName);
            addPreview({
                variables: {
                    previewAddInput: {
                        isUHD: state.isUHD,
                        overrides: serviceState.selected.overrides,
                        serviceName,
                        url: serviceState.selected.url
                    }
                }
            });
        }
    }, [
        addPreview,
        previousServiceState?.selected.url,
        serviceName,
        serviceState.selected.id,
        serviceState.selected.isCreated,
        serviceState.selected.isPreviewLoading,
        serviceState.selected.isPreviewValid,
        serviceState.selected.overrides,
        serviceState.selected.url,
        setSelectedIsPreviewLoading,
        state.isUHD
    ]);
    const display = useMemo(() => {
        if (serviceState.isOnlyNew) {
            return serviceState.display.filter(({ isNew }) => isNew);
        }
        if (serviceState.isOnlyPresent) {
            return serviceState.display.filter(({ isNew }) => !isNew);
        }
        return serviceState.display;
    }, [serviceState.display, serviceState.isOnlyNew, serviceState.isOnlyPresent]);
    const filteredDisplay = useDeepCompareMemoize(useMemo(() => {
        if (serviceState.isShowFlagged) {
            return display;
        }
        return display.filter(({ isFlagged }) => !isFlagged);
    }, [display, serviceState.isShowFlagged]));
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const subheader = useMemo(() => {
        if (serviceState.isOnlyNew) {
            return `Total New: ${filteredDisplay.length}`;
        }
        if (serviceState.isOnlyPresent) {
            return `Total Present: ${filteredDisplay.length}`;
        }
        return `Total Available: ${filteredDisplay.length}`;
    });
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const title = useMemo(() => `${name} Reviews`);
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const avatar = useMemo(() => name.slice(ZERO, THREE));
    const onToggleFlagged = useCallback((id, isFlagged) => () => {
        eightLeggedFreakReviewUpdate({
            onCompleted: () => {
                refetch({
                    eightLeggedFreakListInput: {
                        isUHD: state.isUHD,
                        serviceName
                    }
                });
            },
            variables: {
                eightLeggedFreakReviewUpdateInput: {
                    id,
                    isFlagged: !isFlagged
                },
                serviceName
            }
        });
    }, [eightLeggedFreakReviewUpdate, refetch, serviceName, state.isUHD]);
    const onSelect = useCallback((event) => {
        setSelectedId(event.currentTarget.value, serviceName);
        if (setOMDbId) {
            getOmdbId({
                variables: {
                    id: event.currentTarget.value,
                    serviceName
                }
            });
        }
    }, [getOmdbId, serviceName, setSelectedId, setOMDbId]);
    return {
        actions,
        avatar,
        display: filteredDisplay,
        flagLoading,
        isShowFlagButton: isAdmin,
        onSelect,
        onToggleFlagged,
        selected: serviceState.selected.id,
        serviceName,
        subheader,
        title
    };
};
export default prepareComponent(usePrepareComponent)(memo(Reviews));
