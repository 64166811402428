import { memo, useCallback } from "react";
import AddActions from "./AddActions";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useAddHomeVideoReview from "hooks/useAddHomeVideoReview";
import useImageSelector from "hooks/useImageSelector";
import useReviews from "hooks/useReviews";
import useUser from "hooks/useUser";
const usePrepareComponent = () => {
    const { OMDbId, isAddHomeVideoReviewEnabled, isOmdbIdValid, loading, onAddHomeVideoReview, onClearAll, name } = useAddHomeVideoReview();
    const { setOMDbId, setName } = useReviews();
    const { isAdmin } = useUser();
    const imageSelector = useImageSelector({ serviceType: "reviews" });
    const onOmdbIdChange = useCallback((newOMDbId) => {
        setOMDbId(newOMDbId);
    }, [setOMDbId]);
    const onNameChange = useCallback((newName) => {
        setName(newName);
    }, [setName]);
    return {
        OMDbId,
        imageSelector,
        isAddHomeVideoReviewEnabled,
        isAdmin,
        isOmdbIdValid,
        loading,
        name,
        onAddHomeVideoReview,
        onClearAll,
        onNameChange,
        onOmdbIdChange
    };
};
export default prepareComponent(usePrepareComponent)(memo(AddActions));
