import gql from "graphql-tag"

const SOCIALS_POSTS_ADD = gql`
  mutation socialsPostsAdd($socialsPostsAddInput: SocialsPostsAddInput!) {
    socialsPostsAdd(socialsPostsAddInput: $socialsPostsAddInput) {
      ids
    }
  }
`

export default SOCIALS_POSTS_ADD
