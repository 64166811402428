import authenticate from "./authenticate"
import author from "./author"
import blog from "./blog"
import content from "./content"
import contest from "./contest"
import critics from "./critics"
import dashboard from "./dashboard"
import eightLeggedFreak from "./eightLeggedFreak"
import emailPreferences from "./emailPreferences"
import emails from "./emails"
import forum from "./forum"
import homeVideoReview from "./homeVideoReview"
import homeVideoReviewScore from "./homeVideoReviewScore"
import homeVideoReviewSummary from "./homeVideoReviewSummary"
import image from "./image"
import mas from "./mas"
import mutation from "./mutation"
import news from "./news"
import newsletter from "./newsletter"
import query from "./query"
import scoreAndQuote from "./scoreAndQuote"
import site from "./site"
import socials from "./socials"
import tag from "./tag"

export default [
  authenticate,
  author,
  blog,
  content,
  contest,
  critics,
  dashboard,
  eightLeggedFreak,
  emailPreferences,
  emails,
  forum,
  homeVideoReview,
  homeVideoReviewScore,
  homeVideoReviewSummary,
  image,
  mas,
  mutation,
  news,
  newsletter,
  query,
  scoreAndQuote,
  site,
  socials,
  tag
]
