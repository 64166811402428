/* eslint-disable no-ternary */

import { ComponentProps } from "."
import { Wrapper } from "./styled"

const Button = ({
  bold,
  className,
  disabled,
  icon,
  iconEnd,
  iconOnly,
  label,
  loading,
  noHoverHighlight,
  onClick,
  outlined,
  padded,
  secondary,
  transparent,
  type
}: ComponentProps) => (
  <Wrapper
    bold={bold}
    className={className}
    disabled={disabled}
    icon={icon}
    iconEnd={iconEnd}
    iconOnly={iconOnly}
    label={label}
    loading={loading}
    noHoverHighlight={noHoverHighlight}
    onClick={onClick}
    outlined={outlined}
    padded={padded}
    secondary={secondary}
    transparent={transparent}
    type={type}
  />
)

export default Button
