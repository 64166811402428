import gql from "graphql-tag"

const SOCIALS_AUTHOR_ADD = gql`
  mutation socialsAuthorAdd($socialsAuthorAddInput: SocialsAuthorAddInput!) {
    socialsAuthorAdd(socialsAuthorAddInput: $socialsAuthorAddInput) {
      createdAt
      displayName
      externalId
      id
      platformId
      updatedAt
      username
    }
  }
`

export default SOCIALS_AUTHOR_ADD
