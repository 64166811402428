/* eslint-disable no-ternary */

import { Icon, TextArea } from "@bluframe/blublocks"
import Button from "components/buttons/Button"
import { ComponentProps } from "."
import { Wrapper } from "./styled"

const DEFAULT_ROWS = 5

const CollapsibleTextArea = ({
  id,
  isShow,
  label,
  name,
  onToggle,
  placeholder,
  rows = DEFAULT_ROWS,
  textAreaRef
}: ComponentProps) => (
  <Wrapper>
    <Button
      iconEnd={<Icon name={isShow ? "IoChevronUp" : "IoChevronDown"} />}
      label={label}
      onClick={onToggle}
      transparent
    />
    {isShow ? (
      <TextArea
        inputId={id ? `${id}-collapsible-textarea` : undefined}
        label={label}
        name={name}
        placeholder={placeholder}
        ref={textAreaRef}
        rows={rows}
      />
    ) : null}
  </Wrapper>
)

export default CollapsibleTextArea
