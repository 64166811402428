/* eslint-disable no-ternary, max-lines-per-function, no-nested-ternary, complexity, max-lines */

import {
  Buttons,
  EnrolledUsersCount,
  Form,
  H2,
  Image,
  ImageAndRules,
  Info,
  InputAndImage,
  Inputs,
  InputsRules,
  Main,
  OptedOutUsersCount,
  Preview,
  SubtitleH2,
  TitleAndInfo,
  Top,
  UsersCount
} from "./styled"
import { ComponentProps, MAX_RULES } from "."
import {
  ConfirmModal,
  Content,
  InputText,
  Modal,
  TextArea
} from "@bluframe/blublocks"
import Button from "components/buttons/Button"
import Typography from "components/Typography"

const FIRST = 0
const ONE = 1
const ZERO = 0

const Item = ({
  actionButtonLabel,
  actionButtonOnClick,
  conclusionRef,
  countryIdRef,
  emailPreview,
  endDateRef,
  imageIdRef,
  introRef,
  isAdd,
  isEditMode,
  isModalOpen,
  item,
  modalContent,
  onModalClose,
  onModalConfirmOpen,
  onModalDeleteConfirm,
  onModalSendConfirm,
  onModalSendTestConfirm,
  onPreviewClick,
  onPublishToggle,
  onSubmit,
  outlinedButtonLabel,
  outlinedButtonOnClick,
  ruleRefs,
  rules,
  slugRef,
  startDateRef,
  subtitleRef,
  templateIdRef,
  titleRef,
  users,
  usersContestsEmails
}: ComponentProps) => (
  <Form onSubmit={onSubmit}>
    <Top>
      <TitleAndInfo $isEditMode={isEditMode}>
        {isEditMode ? (
          <>
            <InputText
              defaultValue={item?.title}
              inputId="title"
              label="Title"
              name="title"
              ref={titleRef}
              required
            />
            <InputText
              defaultValue={item?.subtitle}
              inputId="subtitle"
              label="Subtitle"
              name="subtitle"
              ref={subtitleRef}
            />
            <InputText
              defaultValue={item?.slug}
              inputId="slug"
              label="Slug"
              name="slug"
              ref={slugRef}
              required
            />
            <InputText
              defaultValue={item?.startDate}
              inputId="startDate"
              label="Start Date"
              name="startDate"
              ref={startDateRef}
            />

            <InputText
              defaultValue={item?.endDate}
              inputId="endDate"
              label="End Date"
              name="endDate"
              ref={endDateRef}
            />
            <InputText
              defaultValue={item?.countryIds?.[FIRST]}
              inputId="countryId"
              label="Country Id"
              name="countryId"
              ref={countryIdRef}
            />
          </>
        ) : (
          <>
            <Typography.H1>{item?.title}</Typography.H1>
            <Typography.SubtitleH1>{item?.subtitle}</Typography.SubtitleH1>
            <Info>{item?.slug}</Info>
            <Info>{`Start Date: ${item?.startDate}`}</Info>
            <Info>{`End Date: ${item?.endDate}`}</Info>
            <Info>{`Countries: ${item?.countries
              ?.map((country) => country.shortName)
              .join(", ")}`}</Info>
          </>
        )}
      </TitleAndInfo>
      <Buttons>
        <Button
          label={outlinedButtonLabel}
          onClick={outlinedButtonOnClick}
          outlined
        />
        <Button
          label={actionButtonLabel}
          onClick={actionButtonOnClick}
          type={isEditMode ? "submit" : "button"}
        />
        {isAdd ? null : (
          <Button
            label={item?.isPublished ? "Unpublish" : "Publish"}
            onClick={onModalConfirmOpen("publishConfirm")}
            outlined={item?.isPublished}
            secondary
          />
        )}
      </Buttons>
    </Top>
    <Main>
      {isEditMode ? (
        <TextArea
          defaultValue={item?.intro}
          inputId="intro"
          label="Intro"
          name="intro"
          ref={introRef}
        />
      ) : item?.intro ? (
        <Content content={item?.intro} />
      ) : null}
      <ImageAndRules $hasImage={Boolean(item?.image)}>
        <InputAndImage>
          {isEditMode ? (
            <InputText
              defaultValue={String(item?.image?.id ?? "")}
              inputId="imageId"
              label="Image Id"
              name="imageId"
              ref={imageIdRef}
            />
          ) : null}
          {item?.image ? (
            <Image
              alt={item?.image.alternativeText}
              height={item?.image.height}
              src={item?.image.url}
              width={item?.image.width}
            />
          ) : null}
        </InputAndImage>
        {isEditMode ? (
          <InputsRules>
            {/* We need to make this better */}
            {Array.from(Array(MAX_RULES).keys()).map((__, index) => (
              <InputText
                defaultValue={item?.rules?.[index]}
                inputId="rule"
                // eslint-disable-next-line react/no-array-index-key
                key={`rule-${index}`}
                label={`Rule ${index + ONE}`}
                name="rule"
                ref={ruleRefs[index]}
              />
            ))}
          </InputsRules>
        ) : rules ? (
          <Content content={rules} />
        ) : null}
      </ImageAndRules>
      {isEditMode ? (
        <TextArea
          defaultValue={item?.conclusion}
          inputId="conclusion"
          label="Conclusion"
          name="conclusion"
          ref={conclusionRef}
        />
      ) : item?.conclusion ? (
        <Content content={item?.conclusion} />
      ) : null}
      {isAdd ? null : (
        <>
          <H2>{"Email"}</H2>
          <Inputs>
            <InputText
              inputId="templateId"
              label="Email Template Id"
              name="templateId"
              ref={templateIdRef}
            />
            <Button
              label="Preview"
              onClick={onPreviewClick}
              outlined
              secondary
            />
            <Button label="Send" onClick={onModalConfirmOpen("sendConfirm")} />
            <Button
              label="Send Test"
              onClick={onModalConfirmOpen("sendTestConfirm")}
              outlined
              secondary
            />
            <UsersCount>
              <EnrolledUsersCount>{`Enrolled Users: ${
                users?.length ?? ZERO
              }`}</EnrolledUsersCount>
              <OptedOutUsersCount>{`Opted-out Users: ${
                usersContestsEmails?.length ?? ZERO
              }`}</OptedOutUsersCount>
            </UsersCount>
          </Inputs>
          <H2>{"Preview"}</H2>
          <Preview
            dangerouslySetInnerHTML={{
              __html: emailPreview ?? ""
            }}
          />
        </>
      )}
    </Main>
    <Modal isOpen={isModalOpen} onClose={onModalClose}>
      {modalContent === "deleteConfirm" ? (
        <ConfirmModal
          message={`Are you sure you want to delete ${item?.title}?`}
          onClose={onModalClose}
          onConfirm={onModalDeleteConfirm}
        />
      ) : null}
      {modalContent === "publishConfirm" ? (
        <ConfirmModal
          message={`Are you sure you want to ${
            item?.isPublished ? "unpublish" : "publish"
          } ${item?.title}?`}
          onClose={onModalClose}
          onConfirm={onPublishToggle}
        />
      ) : null}
      {modalContent === "sendConfirm" ? (
        <ConfirmModal
          message={
            <>
              <H2>
                {`Are you sure you want to send emails to ${
                  usersContestsEmails?.length ?? ZERO
                } out of ${users?.length ?? ZERO} participants?`}
              </H2>
              <SubtitleH2>
                {`${
                  (users?.length ?? ZERO) -
                  (usersContestsEmails?.length ?? ZERO)
                } users have chosen to opt out and are not going to receive the email`}
              </SubtitleH2>
            </>
          }
          onClose={onModalClose}
          onConfirm={onModalSendConfirm}
        />
      ) : null}
      {modalContent === "sendTestConfirm" ? (
        <ConfirmModal
          message={`Are you sure you want to send a test email to info@mightychroma.me?`}
          onClose={onModalClose}
          onConfirm={onModalSendTestConfirm}
        />
      ) : null}
    </Modal>
  </Form>
)

export default Item
