/* eslint-disable no-ternary, max-lines-per-function */

import { Li, SocialLinksUl, SocialMediaLi, SocialMediaUl } from "./styled"
import { ComponentProps } from "."
import SocialLink from "./SocialLink"

const SocialLinks = ({
  blueSky,
  className,
  facebook,
  hasSocialMedia,
  instagram,
  isBig,
  isShowLabels,
  reddit,
  redditSubreddit,
  threads,
  twitterX,
  website,
  youtube
}: ComponentProps) => (
  <SocialLinksUl aria-label="Social Links" className={className}>
    {hasSocialMedia ? (
      <SocialMediaLi aria-label="Social Media">
        <SocialMediaUl
          $isBig={isBig}
          $isShowLabels={isShowLabels}
          aria-label="Social Media"
        >
          {instagram ? (
            <Li aria-label="Instagram">
              <SocialLink
                iconGroup="fa6"
                iconName="FaInstagram"
                isBig={isBig}
                isShowLabel={isShowLabels}
                link={`https://instagram.com/${instagram}`}
                name={instagram}
                platformName="Instagram"
              />
            </Li>
          ) : null}
          {blueSky ? (
            <Li aria-label="BlueSky">
              <SocialLink
                iconGroup="fa6"
                iconName="FaBluesky"
                isBig={isBig}
                isShowLabel={isShowLabels}
                link={`https://bsky.app/profile/${blueSky}`}
                name={blueSky}
                platformName="BlueSky"
              />
            </Li>
          ) : null}
          {threads ? (
            <Li aria-label="Threads">
              <SocialLink
                iconGroup="fa6"
                iconName="FaThreads"
                isBig={isBig}
                isShowLabel={isShowLabels}
                link={`https://threads.net/@${threads}`}
                name={threads}
                platformName="Threads"
              />
            </Li>
          ) : null}
          {facebook ? (
            <Li aria-label="Facebook">
              <SocialLink
                iconGroup="fa6"
                iconName="FaFacebook"
                isBig={isBig}
                isShowLabel={isShowLabels}
                link={`https://facebook.com/${facebook}`}
                name={facebook}
                platformName="Facebook"
              />
            </Li>
          ) : null}
          {twitterX ? (
            <Li aria-label="Twitter X">
              <SocialLink
                iconGroup="fa6"
                iconName="FaXTwitter"
                isBig={isBig}
                isShowLabel={isShowLabels}
                link={`https://twitter.com/${twitterX}`}
                name={twitterX}
                platformName="Twitter X"
              />
            </Li>
          ) : null}
          {youtube ? (
            <Li aria-label="YouTube">
              <SocialLink
                iconGroup="fa6"
                iconName="FaYoutube"
                isBig={isBig}
                isShowLabel={isShowLabels}
                link={`https://youtube.com/${youtube}`}
                name={youtube}
                platformName="YouTube"
              />
            </Li>
          ) : null}
          {reddit ? (
            <Li aria-label="Reddit">
              <SocialLink
                iconGroup="fa6"
                iconName="FaReddit"
                isBig={isBig}
                isShowLabel={isShowLabels}
                link={`https://reddit.com/user/${reddit}`}
                name={reddit}
                platformName="Reddit"
              />
            </Li>
          ) : null}
          {redditSubreddit ? (
            <Li aria-label="Reddit">
              <SocialLink
                iconGroup="fa6"
                iconName="FaReddit"
                isBig={isBig}
                isShowLabel={isShowLabels}
                link={`https://reddit.com/r/${redditSubreddit}`}
                name={redditSubreddit}
                platformName="Reddit"
              />
            </Li>
          ) : null}
        </SocialMediaUl>
      </SocialMediaLi>
    ) : null}
    {website ? (
      <Li aria-label="Website">
        <SocialLink
          iconGroup="io5"
          iconName="IoGlobeOutline"
          isBig={isBig}
          isShowLabel
          link={website}
          name={website.replace(/https?:\/\//u, "")}
          platformName="Website"
        />
      </Li>
    ) : null}
  </SocialLinksUl>
)

export default SocialLinks
