import BigNumber from "./BigNumber"
import H1 from "./H1"
import H2 from "./H2"
import H3 from "./H3"
import H4 from "./H4"
import Li from "./Li"
import Link from "./Link"
import Paragraph from "./Paragraph"
import SubtitleH1 from "./SubtitleH1"
import SubtitleH2 from "./SubtitleH2"
import SubtitleH3 from "./SubtitleH3"

export default {
  BigNumber,
  H1,
  H2,
  H3,
  H4,
  Li,
  Link,
  Paragraph,
  SubtitleH1,
  SubtitleH2,
  SubtitleH3
}
