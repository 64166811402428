import gql from "graphql-tag"
import homeVideoReview from "fragments/homeVideoReview"

const GET_HOME_VIDEO_REVIEWS_WAVE = gql`
  ${homeVideoReview}

  query getHomeVideoReviewsWave($cursor: String) {
    getHomeVideoReviewsWave(cursor: $cursor) {
      cursor
      hasNextData
      hasPrevData
      homeVideoReviews {
        ...HomeVideoReview
      }
      prevCursor
    }
  }
`
export default GET_HOME_VIDEO_REVIEWS_WAVE
