import gql from "graphql-tag"

const SOCIALS_PLATFORM_GATHER_POSTS = gql`
  mutation socialsPlatformGatherPosts(
    $socialsPlatformGatherPostsInput: SocialsPlatformGatherPostsInput!
  ) {
    socialsPlatformGatherPosts(
      socialsPlatformGatherPostsInput: $socialsPlatformGatherPostsInput
    ) {
      count
    }
  }
`

export default SOCIALS_PLATFORM_GATHER_POSTS
