import { gql } from "graphql-tag"

const socials = gql`
  type SocialsAiInstruction {
    content: String!
    createdAt: String!
    description: String
    id: ID!
    identifier: String!
    name: String!
    order: Int!
    tags: [String!]
    updatedAt: String!
  }

  input SocialsAiInstructionUpdateInput {
    content: String
    description: String
    id: ID!
    identifier: String
    name: String
    order: Int
    tags: [String!]
  }

  type SocialsAiInstructionUpdateResponse {
    id: ID!
  }

  input SocialsAiInstructionAddInput {
    content: String!
    description: String
    identifier: String!
    name: String!
    order: Int!
    tags: [String!]
  }

  type SocialsAiInstructionAddResponse {
    id: ID!
  }

  type SocialsAiInstructionDeleteResponse {
    status: String!
  }

  input SocialsAuthorAddInput {
    displayName: String!
    externalId: ID!
    platformId: ID!
    username: String!
  }

  type SocialsAuthorAddResponse {
    createdAt: String!
    displayName: String!
    externalId: ID!
    id: ID!
    platformId: ID!
    updatedAt: String!
    username: String!
  }

  input SocialsAiInstructionsGetInput {
    limit: Int
    sortBy: String
  }

  input SocialsGeneratePostsInput {
    "Additional Instructions"
    additionalInstructions: String
    "Platform Names"
    platformNames: [String!]!
    "Text"
    text: String!
  }

  type GeneratePost {
    platformName: String!
    text: String!
  }

  type SocialsGeneratePostsResponse {
    posts: [GeneratePost!]!
  }

  input SocialsPlatformAddInput {
    apiVersion: String
    description: String
    maxCharactersPerPost: Int!
    name: String!
    serviceName: String!
    targetCharactersPerPost: Int!
    url: String!
  }

  type SocialsPlatform {
    apiVersion: String
    createdAt: String!
    description: String
    id: ID!
    maxCharactersPerPost: Int!
    name: String!
    serviceName: String!
    targetCharactersPerPost: Int!
    updatedAt: String!
    url: String
  }

  input SocialsPlatformGatherPostsInput {
    "Author Id"
    authorId: String!
    "Limit"
    limit: Int
    "Platform Service Name"
    serviceName: String!
  }

  type SocialsPlatformGatherPostsResponse {
    "Added Count"
    count: Int!
  }

  input SocialsPlatformsGetInput {
    limit: Int
    sortBy: String
  }

  input SocialsPostAddInput {
    authorId: ID!
    externalId: ID!
    likeCount: Int
    platformId: ID!
    postedAt: String!
    quoteCount: Int
    replyCount: Int
    repostCount: Int
    text: String
    url: String!
  }

  input SocialsPostsAddInput {
    posts: [SocialsPostAddInput!]!
  }

  type SocialsPostsAddResponse {
    ids: [ID!]!
  }
`

export default socials
