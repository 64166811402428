import gql from "graphql-tag"

const SOCIALS_GENERATE_POSTS = gql`
  mutation socialsGeneratePosts(
    $socialsGeneratePostsInput: SocialsGeneratePostsInput!
  ) {
    socialsGeneratePosts(
      socialsGeneratePostsInput: $socialsGeneratePostsInput
    ) {
      posts {
        platformName
        text
      }
    }
  }
`

export default SOCIALS_GENERATE_POSTS
