import gql from "graphql-tag"
import socialsPlatform from "fragments/socialsPlatform"

const GET_SOCIALS_PLATFORMS = gql`
  ${socialsPlatform}

  query getSocialsPlatforms(
    $socialsPlatformsGetInput: SocialsPlatformsGetInput
  ) {
    getSocialsPlatforms(socialsPlatformsGetInput: $socialsPlatformsGetInput) {
      ...SocialsPlatform
    }
  }
`

export default GET_SOCIALS_PLATFORMS
