import Autosuggest from "components/Autosuggest"
import { ComponentProps } from "."
import CriticsEditActions from "components/Critics/EditActions"

const EditActions = ({
  isUpdateNewsEnabled,
  label,
  loading,
  newsTitles,
  onClearAll,
  onGetSuggestions,
  onSelectNewsId,
  onUpdateNews,
  selectedNewsId
}: ComponentProps) => (
  <CriticsEditActions
    isUpdateItemEnabled={isUpdateNewsEnabled}
    label={label}
    loading={loading}
    onClearAll={onClearAll}
    onUpdateItem={onUpdateNews}
    topItem={
      <Autosuggest
        isLoading={loading}
        label="Select a News"
        onGetSuggestions={onGetSuggestions}
        onSuggestionClick={onSelectNewsId}
        suggestions={newsTitles}
        value={selectedNewsId}
      />
    }
  />
)

export default EditActions
