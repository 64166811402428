import GeneratePosts from "./GeneratePosts"
import { Props as GeneratePostsProps } from "components/Socials/GeneratePosts"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface Props extends GeneratePostsProps {}

export interface ComponentProps extends Props {}

const usePrepareComponent = (props: Props): ComponentProps => {
  return {
    ...props
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  GeneratePosts
)
