import { gql } from "graphql-tag"

const news = gql`
  type Mutation {
    addNews(news: NewsAddInput!): NewsAddResponse!

    addNewsSource(newsSourceAddInput: NewsSourceAddInput!): NewsSourceResponse!

    addPreviewNewsSource(
      previewNewsSourceAddInput: PreviewNewsSourceAddInput!
    ): PreviewNewsSource!

    generateDraftNews(
      generateDraftsInputs: [GenerateDraftNewsInput!]!
      options: GenerateDraftNewsInputOptions
    ): GenerateDraftNewsResponse!

    publishDraftNews(
      publishDraftsInputs: [PublishDraftNewsInput!]!
      options: PublishDraftNewsInputOptions
    ): PublishDraftNewsResponse!

    updateNews(
      newsUpdateInput: NewsUpdateInput!
      options: NewsUpdateInputOptions
    ): NewsUpdateResponse!

    updateNewsSource(
      serviceName: String!
      newsSourceUpdateInput: NewsSourceUpdateInput!
    ): NewsSourceResponse!

    updateCriticsNewsSourcesFromUrl(
      id: ID!
      url: String!
      serviceName: String!
    ): NewsSourceResponse!
  }
`

export default news
