import gql from "graphql-tag"

const socialsAiInstruction = gql`
  fragment SocialsAiInstruction on SocialsAiInstruction {
    content
    createdAt
    description
    id
    identifier
    name
    order
    tags
    updatedAt
  }
`

export default socialsAiInstruction
