/* eslint-disable max-lines-per-function */

import {
  HomeVideoReviewSearchItem,
  NewsSearchItem
} from "@bluframe/mightychroma-mightytool-frontend-types"
import { KeyboardEvent, useEffect, useState } from "react"
import {
  UseComboboxGetInputPropsReturnValue,
  UseComboboxInputValueChange,
  UseComboboxPropGetters,
  useCombobox
} from "downshift"
import Autosuggest from "./Autosuggest"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface Props {
  disabled?: boolean
  inputId?: string
  isLoading?: boolean
  label: string
  // eslint-disable-next-line no-unused-vars
  onSuggestionClick: (id: string, name: string) => void
  onGetSuggestions: (
    // eslint-disable-next-line no-unused-vars
    changes: UseComboboxInputValueChange<
      HomeVideoReviewSearchItem | NewsSearchItem
    >
  ) => void
  // eslint-disable-next-line no-unused-vars
  onKeyDown?: (ev: KeyboardEvent<Element>) => void
  suggestions: HomeVideoReviewSearchItem[] | NewsSearchItem[]
  value: string
}

export interface ComponentProps extends Omit<Props, "onGetSuggestions"> {
  getItemProps: UseComboboxPropGetters<
    HomeVideoReviewSearchItem | NewsSearchItem
  >["getItemProps"]
  getMenuProps: UseComboboxPropGetters<
    HomeVideoReviewSearchItem | NewsSearchItem
  >["getMenuProps"]
  inputProps: UseComboboxGetInputPropsReturnValue & {
    ref: React.RefObject<HTMLInputElement>
  }
  isOpen: boolean
}

const DEFAULT_DEBOUNCE_DELAY = 500

export const usePrepareComponent = ({
  inputId = "search",
  onGetSuggestions,
  suggestions,
  ...props
}: Props): ComponentProps => {
  const [inputValue, setInputValue] =
    useState<
      UseComboboxInputValueChange<HomeVideoReviewSearchItem | NewsSearchItem>
    >()
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (inputValue === undefined) return

    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    const newTimeoutId = setTimeout(() => {
      onGetSuggestions(inputValue)
    }, DEFAULT_DEBOUNCE_DELAY)

    setTimeoutId(newTimeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  const { isOpen, getMenuProps, getInputProps, getItemProps } = useCombobox<
    HomeVideoReviewSearchItem | NewsSearchItem
  >({
    inputId,
    itemToKey: (item) => item?.id,
    itemToString: (item) =>
      (item?.name || (item as HomeVideoReviewSearchItem)?.mas?.title) ?? "",
    items: suggestions,
    labelId: `${inputId}-label`,
    onInputValueChange: setInputValue
  })

  const inputProps: UseComboboxGetInputPropsReturnValue & {
    ref: React.RefObject<HTMLInputElement>
  } = getInputProps()

  const onSuggestionClick = (id: string, name: string) => {
    props.onSuggestionClick(id, name)

    if (inputProps.ref.current) {
      inputProps.ref.current.value = name
    }
  }

  return {
    ...props,
    getItemProps,
    getMenuProps,
    inputProps,
    isOpen,
    onSuggestionClick,
    suggestions
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Autosuggest
)
