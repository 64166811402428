import gql from "graphql-tag"
import socialsAiInstruction from "fragments/socialsAiInstruction"

const GET_SOCIALS_AI_INSTRUCTION_BY_ID = gql`
  ${socialsAiInstruction}

  query getSocialsAiInstructionById($id: ID!) {
    getSocialsAiInstructionById(id: $id) {
      ...SocialsAiInstruction
    }
  }
`

export default GET_SOCIALS_AI_INSTRUCTION_BY_ID
