/* eslint-disable no-ternary */

import { Theme } from "@bluframe/blublocks"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

export const Wrapper = styled.div`
  position: relative;

  & input[type="text"] {
    border-bottom: 2px solid
      ${({ theme }: { theme: Theme }) => theme.palette.text.main};
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding: 16px 8px 0;

    ${mediaQueries.tablet} {
      padding: 16px 16px 0;
    }

    & + label {
      left: 8px;
      max-width: calc(100% - 20px);
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      ${mediaQueries.tablet} {
        left: 16px;
      }
    }

    ${mediaQueries.tablet} {
      min-width: 250px;
    }
  }
`

export const List = styled.ul`
  background: white;
  font-size: 16px;
  list-style: none;
  margin-left: 0;
  padding: 5px 0;
  position: absolute;
  top: 50px;
  width: 100%;
  z-index: 100;
`
