import gql from "graphql-tag"
import mas from "fragments/mas"

const GET_HOME_VIDEO_REVIEWS = gql`
  ${mas}

  query getHomeVideoReviews($isUHD: Boolean, $limit: Int) {
    getHomeVideoReviews(isUHD: $isUHD, limit: $limit) {
      id
      mas {
        ...MAS
      }
    }
  }
`

export default GET_HOME_VIDEO_REVIEWS
