import { equals } from "ramda";
import { useRef } from "react";
const useDeepCompareMemoize = (value) => {
    const ref = useRef();
    if (!equals(value, ref.current)) {
        ref.current = value;
    }
    return ref.current ?? value;
};
export default useDeepCompareMemoize;
