import { Theme } from "@bluframe/blublocks"
import styled from "styled-components"

export const Wrapper = styled.div`
  display: grid;
  justify-items: start;
  row-gap: 10px;

  & button[type="button"] {
    color: ${({ theme }: { theme: Theme }) => theme.palette.subtitle.main};
    padding: 0;

& svg {
color: ${({ theme }: { theme: Theme }) => theme.palette.subtitle.main};
  }
`
