import gql from "graphql-tag"

const VALIDATE_PREVIEW = gql`
  mutation validatePreview($previewValidateInput: PreviewAddInput!) {
    validatePreview(previewValidateInput: $previewValidateInput) {
      errorFields
      isValid
      serviceName
    }
  }
`
export default VALIDATE_PREVIEW
