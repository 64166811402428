/* eslint-disable no-ternary */

import {
  NewsServiceName,
  ReviewsServiceName
} from "@bluframe/mightychroma-mightytool-frontend-types"
import { RefObject, useRef } from "react"
import Draft from "./Draft"
import { OptionValue } from "@bluframe/blublocks"
import prepareComponent from "@bluframe/grapple/prepareComponent"

export interface DraftPreview {
  image?: string
  releaseDate?: string
  serviceName: NewsServiceName | ReviewsServiceName
  url: string
}

export interface Props {
  id: string
  isChecked: boolean
  isGenerateLoading?: boolean
  isPublishLoading: boolean
  // eslint-disable-next-line no-unused-vars
  onChange: (value: OptionValue) => void
  // eslint-disable-next-line no-unused-vars
  onEdit?: (id: string) => void
  onGenerate?: (
    // eslint-disable-next-line no-unused-vars
    id: string,
    // eslint-disable-next-line no-unused-vars
    overrides?: {
      additionalInstructions: string | undefined
    }
  ) => void
  onPublish: (
    // eslint-disable-next-line no-unused-vars
    id: string,
    // eslint-disable-next-line no-unused-vars
    overrides?: {
      additionalInstructions: string | undefined
    }
  ) => void
  previews: DraftPreview[]
  title: string
}

export interface ComponentProps
  extends Omit<Props, "onEdit" | "onGenerate" | "onPublish"> {
  onEdit?: () => void
  onGenerate?: () => void
  onPublish: () => void
  textAreaRef: RefObject<HTMLTextAreaElement>
}

const usePrepareComponent = (props: Props): ComponentProps => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const onEdit = props.onEdit
    ? () => {
        props.onEdit?.(props.id)
      }
    : undefined

  const onGenerate = props.onGenerate
    ? () => {
        props.onGenerate?.(
          props.id,
          textAreaRef.current?.value
            ? { additionalInstructions: textAreaRef.current?.value }
            : undefined
        )
      }
    : undefined

  const onPublish = () => {
    props.onPublish(
      props.id,
      textAreaRef.current?.value
        ? { additionalInstructions: textAreaRef.current?.value }
        : undefined
    )
  }

  return {
    ...props,
    onEdit,
    onGenerate,
    onPublish,
    textAreaRef
  }
}

export default prepareComponent<Props, ComponentProps>(usePrepareComponent)(
  Draft
)
