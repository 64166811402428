/* eslint-disable no-ternary, max-lines-per-function, no-nested-ternary, complexity, max-lines */

import {
  Actions,
  Form,
  Image,
  Info,
  InputAndButton,
  InputAndImage,
  Main,
  Name,
  NameAndInfo,
  Subheading,
  Top
} from "./styled"
import {
  ConfirmModal,
  Content,
  Icon,
  InputText,
  Modal,
  TextArea
} from "@bluframe/blublocks"
import Button from "components/buttons/Button"
import { ComponentProps } from "."

const Item = ({
  actionButtonLabel,
  actionButtonOnClick,
  additionalInstructionsRef,
  areActionsDisabled,
  imageIdRef,
  isAdd,
  isEditMode,
  isGenerateLoading,
  isModalOpen,
  isPublishLoading,
  isUpdateLoading,
  item,
  modalContent,
  nameRef,
  onGenerateSlug,
  onModalClose,
  onModalConfirmOpen,
  onModalDeleteConfirm,
  onModalGenerateConfirm,
  onModalPublishToggleConfirm,
  onSubmit,
  outlinedButtonLabel,
  outlinedButtonOnClick,
  slugRef,
  subheadingRef,
  textRef
}: ComponentProps) => (
  <Form onSubmit={onSubmit}>
    <Top>
      <Main>
        <NameAndInfo $isEditMode={isEditMode}>
          {isEditMode ? (
            <>
              <InputText
                defaultValue={item?.name}
                inputId="name"
                label="Name"
                name="name"
                ref={nameRef}
                required
              />
              <TextArea
                defaultValue={item?.subheading}
                inputId="subheading"
                label="Subheading"
                name="subheading"
                ref={subheadingRef}
                rows={3}
              />
              <InputAndButton>
                <InputText
                  defaultValue={item?.slug}
                  inputId="slug"
                  label="Slug"
                  name="slug"
                  ref={slugRef}
                  required
                />
                <Button
                  icon={<Icon group="fa6" name="FaRotateLeft" />}
                  iconOnly
                  label="Generate Slug"
                  onClick={onGenerateSlug}
                  outlined
                  transparent
                />
              </InputAndButton>
            </>
          ) : (
            <>
              <Name>{item?.name}</Name>
              <Subheading>{item?.subheading}</Subheading>
              <Info>{item?.slug}</Info>
            </>
          )}
        </NameAndInfo>
        <InputAndImage>
          {isEditMode ? (
            <InputText
              defaultValue={String(item?.image?.id ?? "")}
              inputId="imageId"
              label="Image Id"
              name="imageId"
              ref={imageIdRef}
            />
          ) : null}
          {item?.image ? (
            <Image
              alt={item?.image.alternativeText}
              height={item?.image.height}
              src={item?.image.url}
              width={item?.image.width}
            />
          ) : null}
        </InputAndImage>

        {isEditMode ? (
          <TextArea
            defaultValue={item?.text}
            inputId="text"
            label="Text"
            name="text"
            ref={textRef}
          />
        ) : item?.text ? (
          <Content content={item?.text} />
        ) : null}
      </Main>
      <Actions aria-label="Actions" role="region">
        <Button
          disabled={areActionsDisabled}
          label={outlinedButtonLabel}
          onClick={outlinedButtonOnClick}
          outlined
        />
        <Button
          disabled={areActionsDisabled}
          label={actionButtonLabel}
          loading={isEditMode ? isUpdateLoading : false}
          onClick={actionButtonOnClick}
          type={isEditMode ? "submit" : "button"}
        />
        <Button
          disabled={areActionsDisabled}
          label="Generate"
          loading={isGenerateLoading}
          onClick={onModalConfirmOpen("generateConfirm")}
          outlined
          secondary
        />
        {isAdd ? null : (
          <Button
            disabled={areActionsDisabled}
            label={item?.isPublished ? "Unpublish" : "Publish"}
            loading={isPublishLoading}
            onClick={onModalConfirmOpen("publishConfirm")}
            outlined={item?.isPublished}
            secondary
          />
        )}
        <TextArea
          inputId="additionalInstructions"
          label="Additional Instructions"
          name="additionalInstructions"
          ref={additionalInstructionsRef}
          rows={3}
        />
      </Actions>
    </Top>

    <Modal isOpen={isModalOpen} onClose={onModalClose}>
      {modalContent === "deleteConfirm" ? (
        <ConfirmModal
          message={`Are you sure you want to delete ${item?.name}?`}
          onClose={onModalClose}
          onConfirm={onModalDeleteConfirm}
        />
      ) : null}
      {modalContent === "generateConfirm" ? (
        <ConfirmModal
          message={`Are you sure you want to generate ${item?.name}?`}
          onClose={onModalClose}
          onConfirm={onModalGenerateConfirm}
        />
      ) : null}
      {modalContent === "publishConfirm" ? (
        <ConfirmModal
          message={`Are you sure you want to ${
            item?.isPublished ? "unpublish" : "publish"
          } ${item?.name}?`}
          onClose={onModalClose}
          onConfirm={onModalPublishToggleConfirm}
        />
      ) : null}
    </Modal>
  </Form>
)

export default Item
