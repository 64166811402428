/* eslint-disable max-statements */
export const onCreateReview = (createReview, isReady) => {
    if (isReady) {
        createReview();
    }
};
const createReviews = ({ addAvForums, addAvNirvana, addAvsForum, addBluRay, addBluRayAuthority, addDigitalBits, addDoBlu, addHidefDigest, addHighDefDiscNews, addHomeTheaterForum, addTheaterByte, addWhySoBlu, avForumsReady, avNirvanaReady, avsForumReady, bluRayAuthorityReady, bluRayReady, digitalBitsReady, doBluReady, hidefDigestReady, highDefDiscNewsReady, homeTheaterForumReady, theaterByteReady, whySoBluReady }) => {
    onCreateReview(addAvForums, avForumsReady);
    onCreateReview(addAvNirvana, avNirvanaReady);
    onCreateReview(addAvsForum, avsForumReady);
    onCreateReview(addBluRay, bluRayReady);
    onCreateReview(addBluRayAuthority, bluRayAuthorityReady);
    onCreateReview(addDigitalBits, digitalBitsReady);
    onCreateReview(addDoBlu, doBluReady);
    onCreateReview(addHidefDigest, hidefDigestReady);
    onCreateReview(addHighDefDiscNews, highDefDiscNewsReady);
    onCreateReview(addHomeTheaterForum, homeTheaterForumReady);
    onCreateReview(addTheaterByte, theaterByteReady);
    onCreateReview(addWhySoBlu, whySoBluReady);
};
export default createReviews;
