import {
  NewsServiceName,
  NewsSource,
  Review,
  ReviewsServiceName
} from "@bluframe/mightychroma-mightytool-frontend-types"
import Card from "./Card"
import { RadioGroupProps } from "@material-ui/core/RadioGroup"

export interface Props {
  actions: React.ReactNode
  avatar: string
  display: NewsSource[] | Review[]
  flagLoading: boolean
  isShowFlagButton: boolean
  label: string
  name: string
  onSelect: RadioGroupProps["onChange"]
  // eslint-disable-next-line no-unused-vars
  onToggleFlagged: (id: string, isFlagged: boolean) => () => void
  selected: string
  serviceName: NewsServiceName | ReviewsServiceName
  subheader: string
  title: string
}

export interface ComponentProps extends Props {}

export default Card
