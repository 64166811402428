import gql from "graphql-tag"

const GENERATE_DRAFT_NEWS = gql`
  mutation generateDraftNews(
    $generateDraftsInputs: [GenerateDraftNewsInput!]!
    $options: GenerateDraftNewsInputOptions
  ) {
    generateDraftNews(
      generateDraftsInputs: $generateDraftsInputs
      options: $options
    ) {
      ids
    }
  }
`
export default GENERATE_DRAFT_NEWS
