import { SubtitleH2 as BluBlocksSubtitleH2 } from "@bluframe/blublocks"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

const SubtitleH2 = styled(BluBlocksSubtitleH2)`
  font-size: 16px;
  font-weight: 700;
  margin: 0;

  ${mediaQueries.tablet} {
    font-size: 18px;
  }
`

export default SubtitleH2
