/* eslint-disable no-ternary */

import { Loading, Reset } from "@bluframe/blublocks"
import { Ul, Wrapper } from "./styled"
import { ComponentProps } from "."
import Section from "./Section"
import Typography from "components/Typography"

const Dashboard = ({ isLoading, sections }: ComponentProps) => (
  <Wrapper aria-labelledby="dashboard" role="region">
    <Typography.H1 id="dashboard">{"Dashboard"}</Typography.H1>
    {isLoading ? (
      <Loading size={24} />
    ) : (
      <Ul aria-label="Sections">
        {sections.map((section) => (
          <Reset.Li key={section.id}>
            <Section {...section} />
          </Reset.Li>
        ))}
      </Ul>
    )}
  </Wrapper>
)

export default Dashboard
