import gql from "graphql-tag"

const PUBLISH_DRAFT_NEWS = gql`
  mutation publishDraftNews(
    $publishDraftsInputs: [PublishDraftNewsInput!]!
    $options: PublishDraftNewsInputOptions
  ) {
    publishDraftNews(
      publishDraftsInputs: $publishDraftsInputs
      options: $options
    ) {
      ids
    }
  }
`
export default PUBLISH_DRAFT_NEWS
