import blog from "./blog"
import contests from "./contests"
import critics from "./critics"
import eightLeggedFreak from "./eightLeggedFreak"
import emails from "./emails"
import forum from "./forum"
import { gql } from "graphql-tag"
import homeVideoReviews from "./homeVideoReviews"
import login from "./login"
import mas from "./mas"
import news from "./news"
import newsletters from "./newsletters"
import socials from "./socials"

const mutation = gql`
  ${blog}
  ${contests}
  ${critics}
  ${emails}
  ${eightLeggedFreak}
  ${forum}
  ${homeVideoReviews}
  ${login}
  ${mas}
  ${news}
  ${newsletters}
  ${socials}
`

export default mutation
