import gql from "graphql-tag"

const SOCIALS_AI_INSTRUCTION_DELETE = gql`
  mutation socialsAiInstructionDelete($id: ID!) {
    socialsAiInstructionDelete(id: $id) {
      status
    }
  }
`

export default SOCIALS_AI_INSTRUCTION_DELETE
