import { SubtitleH1 as BluBlocksSubtitleH1 } from "@bluframe/blublocks"
import mediaQueries from "styles/mediaQueries"
import styled from "styled-components"

const SubtitleH1 = styled(BluBlocksSubtitleH1)`
  font-size: 18px;
  font-weight: 700;
  margin: 0;

  ${mediaQueries.tablet} {
    font-size: 20px;
  }
`

export default SubtitleH1
