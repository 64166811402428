import gql from "graphql-tag"
import socialsAiInstruction from "fragments/socialsAiInstruction"

const GET_SOCIALS_AI_INSTRUCTIONS = gql`
  ${socialsAiInstruction}

  query getSocialsAiInstructions(
    $socialsAiInstructionsGetInput: SocialsAiInstructionsGetInput
  ) {
    getSocialsAiInstructions(
      socialsAiInstructionsGetInput: $socialsAiInstructionsGetInput
    ) {
      ...SocialsAiInstruction
    }
  }
`

export default GET_SOCIALS_AI_INSTRUCTIONS
