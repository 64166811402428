import { gql } from "graphql-tag"

const eightLeggedFreak = gql`
  type RawEightLeggedFreakReview {
    "Review Id"
    id: ID!
    "Is Flagged"
    isFlagged: Boolean!
    "Review Title"
    title: String!
    "Review Url"
    url: String!
  }

  type EightLeggedFreakReview {
    "Review Id"
    id: ID!
    "Is Flagged"
    isFlagged: Boolean!
    "Is Review New"
    isNew: Boolean!
    "Review Title"
    title: String!
    "Review Url"
    url: String!
  }

  input EightLeggedFreakGatherInput {
    isAllAroundGather: Boolean
    isBluRay: Boolean!
    isUHD: Boolean!
    serviceName: String!
  }

  input EightLeggedFreakListInput {
    cursor: String
    isUHD: Boolean!
    serviceName: String!
    matchTitle: String
  }

  input EightLeggedFreakReviewUpdateInput {
    id: ID!
    isFlagged: Boolean
    title: String
    url: String
  }

  type EightLeggedFreakGather {
    "Status"
    status: String!
  }

  type EightLeggedFreakListResponse {
    links: [EightLeggedFreakReview]
    nextCursor: String
  }

  type EightLeggedFreakReviewResponse {
    id: ID!
  }

  type EightLeggedFreakNewsSource {
    id: ID!
    isFlagged: Boolean!
    isNew: Boolean!
    name: String!
    url: String!
  }

  input EightLeggedFreakNewsSourcesGatherInput {
    isAllAroundGather: Boolean
    serviceName: String!
  }

  input EightLeggedFreakNewsSourcesListInput {
    cursor: String
    serviceName: String!
  }

  input EightLeggedFreakNewsSourceUpdateInput {
    id: ID!
    isFlagged: Boolean
    name: String
    url: String
  }

  type EightLeggedFreakNewsSourcesGather {
    status: String!
  }

  type EightLeggedFreakNewsSourcesListResponse {
    links: [EightLeggedFreakNewsSource]
    nextCursor: String
  }

  type EightLeggedFreakNewsSourceResponse {
    id: ID!
  }
`

export default eightLeggedFreak
