/* eslint-disable no-ternary */

import { ComponentProps } from "."
import GeneratePostsComponent from "components/Socials/GeneratePosts"
import Typography from "components/Typography"
import { Wrapper } from "./styled"

const GeneratePosts = (props: ComponentProps) => (
  <Wrapper>
    <Typography.H1>{"Generate Posts"}</Typography.H1>
    <GeneratePostsComponent {...props} />
  </Wrapper>
)

export default GeneratePosts
