/* eslint-disable max-lines-per-function */
import { memo, useCallback, useMemo } from "react";
import { mutations, queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useMutation, useQuery } from "@apollo/client";
import HomeVideoReviews from "./HomeVideoReviews";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { reviewsServiceNames } from "config/site/serviceNames";
import { useLocation } from "react-router-dom";
const { homeVideoReviews: { PUBLISH_DRAFT_HOME_VIDEO_REVIEW } } = mutations;
const { homeVideoReviews: { GET_DRAFTS_WAVE } } = queries;
const usePrepareComponent = () => {
    const { search } = useLocation();
    const startAt = new URLSearchParams(search).get("startAt");
    /**
     * Get drafts wave query
     */
    const { data, loading } = useQuery(GET_DRAFTS_WAVE, {
        fetchPolicy: "network-only",
        variables: {
            cursor: startAt ?? undefined
        }
    });
    /**
     * Publish draft mutation
     */
    const [publishDrafts, { loading: draftsLoading }] = useMutation(PUBLISH_DRAFT_HOME_VIDEO_REVIEW, {
        refetchQueries: [GET_DRAFTS_WAVE]
    });
    /**
     * Publish draft
     */
    const onPublish = useCallback((id) => {
        publishDrafts({
            variables: {
                ids: [id]
            }
        });
    }, [publishDrafts]);
    /**
     * Get previews from draft
     */
    const getPreviewsFromDraft = useCallback((draft) => {
        const previews = [];
        reviewsServiceNames.forEach((serviceName) => {
            const review = draft[serviceName];
            if (review) {
                previews.push({
                    releaseDate: review.releaseDate ?? undefined,
                    serviceName,
                    url: review.url ?? ""
                });
            }
        });
        return previews;
    }, []);
    /**
     * Map drafts to DraftsListProps format
     * Filter out drafts without a name or title
     */
    const drafts = useMemo(() => data?.draftsWave.homeVideoReviews
        .filter((draft) => Boolean(draft.name ?? draft.mas?.title))
        .map((draft) => ({
        id: draft.id,
        isChecked: false,
        isPublishLoading: draftsLoading,
        // eslint-disable-next-line no-empty-function
        onChange: () => { },
        onPublish: () => onPublish(draft.id),
        previews: getPreviewsFromDraft(draft),
        // Typescript needs the `as string` here to satisfy the type checker
        // we filter out drafts without a name or title above
        title: (draft.name ?? draft.mas?.title)
    })), [
        data?.draftsWave.homeVideoReviews,
        draftsLoading,
        getPreviewsFromDraft,
        onPublish
    ]);
    /**
     * Pagination props
     */
    const pagination = useMemo(() => ({
        hasNextData: data?.draftsWave.hasNextData,
        hasPrevData: data?.draftsWave.hasPrevData,
        link: "/drafts/home-video-reviews",
        next: data?.draftsWave.cursor,
        prev: data?.draftsWave.prevCursor
    }), [data?.draftsWave]);
    return {
        drafts,
        loading,
        pagination
    };
};
export default prepareComponent(usePrepareComponent)(memo(HomeVideoReviews));
