/* eslint-disable no-ternary, no-nested-ternary */

import Paragraph from "components/Typography/Paragraph"
import { Theme } from "styles/theme"
import { rgba } from "polished"
import styled from "styled-components"

const DISABLED_ALPHA = 0.38

export const TextAreaWrapper = styled.div<{
  $hasCharactersCount?: boolean
  $error?: boolean
  $isExpanded?: boolean
  $success?: boolean
}>`
  background: ${({ disabled, theme }: { disabled?: boolean; theme: Theme }) =>
    disabled
      ? rgba(theme.palette.disabled.main, DISABLED_ALPHA)
      : theme.palette.text.contrast};
  border-bottom: 1px solid
    ${({
      $error,
      $success,
      theme
    }: {
      $error?: boolean
      $success?: boolean
      theme: Theme
    }) =>
      $error
        ? theme.palette.error.main
        : $success
        ? theme.palette.success.main
        : theme.colors.gray.light};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: ${({ $hasCharactersCount }: { $hasCharactersCount?: boolean }) =>
    $hasCharactersCount ? "40px 18px" : "40px 18px 26px"};
  position: relative;
  ${({ $isExpanded }: { $isExpanded?: boolean }) =>
    $isExpanded ? "width: 100%;" : ""}

  &:focus-within {
    border-bottom: 1px solid
      ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
  }
`

export const TextAreaInput = styled.textarea`
  border: 0;
  box-shadow: none;
  color: ${({ disabled, theme }: { disabled?: boolean; theme: Theme }) =>
    disabled ? theme.palette.text.light : theme.palette.text.main};
  margin: 0;
  padding: 0;
  resize: none;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  &:focus {
    border: 0;
    box-shadow: none;
  }

  :not(:placeholder-shown) + label,
  &:focus + label {
    font-size: 14px;
    left: 16px;
    top: 10px;

    & svg {
      height: 12px;
      width: 12px;
    }
  }
`

export const Label = styled.label`
  color: ${({ theme }: { theme: Theme }) => theme.palette.text.light};
  font-size: 16px;
  left: 16px;
  pointer-events: none;
  position: absolute;
  top: 10px;
  transition: all 200ms;
`

export const ErrorText = styled.div`
  color: ${({ theme }: { theme: Theme }) => theme.palette.error.main};
  font-size: 12px;
`

export const InfoText = styled.div`
  color: ${({ theme }: { theme: Theme }) => theme.palette.info.main};
  font-size: 12px;
`

export const SuccessText = styled.div`
  color: ${({ theme }: { theme: Theme }) => theme.palette.success.main};
  font-size: 12px;
`

export const CharactersCount = styled(Paragraph)<{
  $isMaxCharactersExceeded?: boolean
}>`
  color: ${({
    $isMaxCharactersExceeded,
    theme
  }: {
    $isMaxCharactersExceeded?: boolean

    theme: Theme
  }) =>
    $isMaxCharactersExceeded
      ? theme.palette.error.main
      : theme.palette.subtitle.main};
  bottom: 8px;
  font-size: 14px;
  margin: 0;
  position: absolute;
  right: 16px;
`
