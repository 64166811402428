import {
  IoAccessibilityOutline,
  IoAdd,
  IoAddCircle,
  IoAlertCircleOutline,
  IoArrowBack,
  IoArrowForward,
  IoBagOutline,
  IoBookmarkOutline,
  IoBulbOutline,
  IoCaretUp,
  IoCartOutline,
  IoCashOutline,
  IoChatbubbleEllipsesOutline,
  IoChatbubbleOutline,
  IoCheckmark,
  IoChevronBack,
  IoChevronBackCircleOutline,
  IoChevronDown,
  IoChevronForward,
  IoChevronUp,
  IoClose,
  IoCloudOutline,
  IoEarthOutline,
  IoEaselOutline,
  IoFilmOutline,
  IoGlobeOutline,
  IoHeart,
  IoHeartOutline,
  IoHelpOutline,
  IoHomeOutline,
  IoImageOutline,
  IoLaptopOutline,
  IoLeafOutline,
  IoLinkOutline,
  IoLocationOutline,
  IoLogoFacebook,
  IoLogoTwitter,
  IoMail,
  IoMailOutline,
  IoMapOutline,
  IoMegaphoneSharp,
  IoNewspaperOutline,
  IoPaperPlaneOutline,
  IoPencilOutline,
  IoPeopleOutline,
  IoPerson,
  IoPhonePortraitOutline,
  IoRocketSharp,
  IoSearch,
  IoSettingsOutline,
  IoShareSocialOutline,
  IoShieldCheckmark,
  IoSpeedometerOutline,
  IoStopwatchOutline,
  IoTrashOutline,
  IoTrendingDownOutline,
  IoTrendingUpOutline,
  IoTrophyOutline,
  IoWarningOutline
} from "react-icons/io5"

const ionIcons = Object.freeze({
  IoAccessibilityOutline,
  IoAdd,
  IoAddCircle,
  IoAlertCircleOutline,
  IoArrowBack,
  IoArrowForward,
  IoBagOutline,
  IoBookmarkOutline,
  IoBulbOutline,
  IoCaretUp,
  IoCartOutline,
  IoCashOutline,
  IoChatbubbleEllipsesOutline,
  IoChatbubbleOutline,
  IoCheckmark,
  IoChevronBack,
  IoChevronBackCircleOutline,
  IoChevronDown,
  IoChevronForward,
  IoChevronUp,
  IoClose,
  IoCloudOutline,
  IoEarthOutline,
  IoEaselOutline,
  IoFilmOutline,
  IoGlobeOutline,
  IoHeart,
  IoHeartOutline,
  IoHelpOutline,
  IoHomeOutline,
  IoImageOutline,
  IoLaptopOutline,
  IoLeafOutline,
  IoLinkOutline,
  IoLocationOutline,
  IoLogoFacebook,
  IoLogoTwitter,
  IoMail,
  IoMailOutline,
  IoMapOutline,
  IoMegaphoneSharp,
  IoNewspaperOutline,
  IoPaperPlaneOutline,
  IoPencilOutline,
  IoPeopleOutline,
  IoPerson,
  IoPhonePortraitOutline,
  IoRocketSharp,
  IoSearch,
  IoSettingsOutline,
  IoShareSocialOutline,
  IoShieldCheckmark,
  IoSpeedometerOutline,
  IoStopwatchOutline,
  IoTrashOutline,
  IoTrendingDownOutline,
  IoTrendingUpOutline,
  IoTrophyOutline,
  IoWarningOutline
})

export default ionIcons
