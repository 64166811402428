/* eslint-disable no-ternary */
import { Theme } from "@bluframe/blublocks"
import Typography from "components/Typography"
import styled from "styled-components"

export const Form = styled.form`
  display: grid;
  row-gap: 20px;
`

export const Top = styled.div`
  align-items: start;
  column-gap: 40px;
  display: grid;
  grid-template-columns: 1fr auto;
`

export const Main = styled.div`
  display: grid;
  row-gap: 20px;
`

export const NameAndInfo = styled.div<{ $isEditMode: boolean }>`
  display: grid;
  row-gap: ${({ $isEditMode }) => ($isEditMode ? "20px" : "10px")};
`

export const Actions = styled.div`
  column-gap: 8px;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 20px;

  & > div {
    grid-column: span 2;
  }
`

export const Info = styled(Typography.Paragraph)`
  color: ${({ theme }: { theme: Theme }) => theme.palette.subtitle.main};
`

export const Name = styled(Typography.H1)`
  font-size: 24px;
  margin: 0;
`

export const Description = styled(Typography.SubtitleH3)`
  line-height: 1.4;
`
