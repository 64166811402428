/* eslint-disable max-lines-per-function, no-ternary, no-nested-ternary */
import { mutations, queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useLazyQuery, useMutation } from "@apollo/client";
import Add from "./Add";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useHistory } from "react-router-dom";
const { socials: { GET_SOCIALS_AI_INSTRUCTIONS } } = queries;
const { socials: { SOCIALS_AI_INSTRUCTION_ADD } } = mutations;
const DEFAULT_ORDER = 0;
const usePrepareComponent = () => {
    const history = useHistory();
    const [socialsAiInstructionAdd, { loading }] = useMutation(SOCIALS_AI_INSTRUCTION_ADD, {
        refetchQueries: [GET_SOCIALS_AI_INSTRUCTIONS]
    });
    const [getSocialsAiInstructions] = useLazyQuery(GET_SOCIALS_AI_INSTRUCTIONS);
    const onCancel = () => {
        history.push("/socials/ai-instructions");
    };
    const onSave = (itemAdd) => {
        socialsAiInstructionAdd({
            onCompleted: () => {
                getSocialsAiInstructions({
                    fetchPolicy: "network-only",
                    onCompleted: () => {
                        history.push("/socials/ai-instructions");
                    }
                });
            },
            variables: {
                socialsAiInstructionAddInput: {
                    ...itemAdd,
                    content: itemAdd.content ?? "",
                    description: itemAdd.description ?? "",
                    identifier: itemAdd.identifier ?? "",
                    name: itemAdd.name ?? "",
                    order: itemAdd.order ?? DEFAULT_ORDER
                }
            }
        });
    };
    return {
        isSaveLoading: loading,
        onCancel,
        onSave
    };
};
export default prepareComponent(usePrepareComponent)(Add);
