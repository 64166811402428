import {
  FaBlog,
  FaBluesky,
  FaCalendarDays,
  FaEnvelopeOpenText,
  FaFacebook,
  FaFilm,
  FaHouse,
  FaInstagram,
  FaLinkedin,
  FaPencil,
  FaPhone,
  FaQuoteLeft,
  FaQuoteRight,
  FaReddit,
  FaRegBookmark,
  FaRegClock,
  FaRegCommentDots,
  FaRegEnvelope,
  FaRegFileLines,
  FaRegImages,
  FaRegMap,
  FaRegNewspaper,
  FaRotateLeft,
  FaShareFromSquare,
  FaThreads,
  FaTrophy,
  FaXTwitter,
  FaYoutube
} from "react-icons/fa6"

const fa6Icons = Object.freeze({
  FaBlog,
  FaBluesky,
  FaCalendarDays,
  FaEnvelopeOpenText,
  FaFacebook,
  FaFilm,
  FaHouse,
  FaInstagram,
  FaLinkedin,
  FaPencil,
  FaPhone,
  FaQuoteLeft,
  FaQuoteRight,
  FaReddit,
  FaRegBookmark,
  FaRegClock,
  FaRegCommentDots,
  FaRegEnvelope,
  FaRegFileLines,
  FaRegImages,
  FaRegMap,
  FaRegNewspaper,
  FaRotateLeft,
  FaShareFromSquare,
  FaThreads,
  FaTrophy,
  FaXTwitter,
  FaYoutube
})

export default fa6Icons
