export const newsServiceNames = [
    "avNirvana",
    "bluRay",
    "hidefDigest",
    "whySoBlu"
];
export const reviewsServiceNames = [
    "avForums",
    "avNirvana",
    "avsForum",
    "bluRay",
    "bluRayAuthority",
    "digitalBits",
    "doBlu",
    "hidefDigest",
    "highDefDiscNews",
    "homeTheaterForum",
    "theaterByte",
    "whySoBlu"
];
