import Main from "./Main";
import { memo } from "react";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import useReviews from "hooks/useReviews";
import useUser from "hooks/useUser";
const usePrepareComponent = () => {
    const { isAdmin } = useUser();
    const { setOMDbId } = useReviews();
    return {
        isAdmin,
        setOMDbId
    };
};
export default prepareComponent(usePrepareComponent)(memo(Main));
