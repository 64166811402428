/* eslint-disable no-ternary, max-lines-per-function, no-nested-ternary, max-statements */
import { mutations, queries } from "@bluframe/mightychroma-mightytool-graphql-schema";
import { useMutation, useQuery } from "@apollo/client";
import Item from "./Item";
import prepareComponent from "@bluframe/grapple/prepareComponent";
import { useParams } from "react-router-dom";
import useToggle from "@bluframe/grapple/useToggle";
const { news: { GET_NEWS } } = queries;
const { news: { GENERATE_DRAFT_NEWS, PUBLISH_DRAFT_NEWS, UPDATE_NEWS } } = mutations;
const usePrepareComponent = () => {
    const [isEditing, toggleIsEditing] = useToggle();
    const params = useParams();
    const { data: newsData, loading } = useQuery(GET_NEWS, {
        fetchPolicy: "network-only",
        variables: {
            id: params.id
        }
    });
    const [updateNews, { loading: updateNewsLoading }] = useMutation(UPDATE_NEWS, {
        refetchQueries: [{ query: GET_NEWS, variables: { id: params.id } }]
    });
    const [generateDrafts, { loading: generateDraftsLoading }] = useMutation(GENERATE_DRAFT_NEWS, {
        refetchQueries: [{ query: GET_NEWS, variables: { id: params.id } }]
    });
    const [publishDrafts, { loading: publishDraftsLoading }] = useMutation(PUBLISH_DRAFT_NEWS, {
        refetchQueries: [{ query: GET_NEWS, variables: { id: params.id } }]
    });
    const onCancel = () => {
        toggleIsEditing();
    };
    const onEdit = () => {
        toggleIsEditing();
    };
    const onGenerate = (additionalInstructions) => {
        generateDrafts({
            variables: {
                generateDraftsInputs: [
                    {
                        additionalInstructions,
                        id: params.id
                    }
                ]
            }
        });
    };
    const onPublishToggle = () => {
        if (newsData?.getNews?.isPublished) {
            updateNews({
                variables: { newsUpdateInput: { id: params.id, isPublished: false } }
            });
        }
        else {
            publishDrafts({
                variables: {
                    publishDraftsInputs: [
                        {
                            id: params.id
                        }
                    ]
                }
            });
        }
    };
    const onSave = (newsUpdateInput) => {
        updateNews({
            onCompleted: () => {
                if (isEditing) {
                    toggleIsEditing();
                }
            },
            variables: {
                newsUpdateInput: {
                    ...newsUpdateInput,
                    id: params.id
                }
            }
        });
    };
    const onDelete = () => {
        // eslint-disable-next-line no-console
        console.log("Delete News");
    };
    return {
        isEditing,
        isGenerateLoading: generateDraftsLoading,
        isPublishLoading: publishDraftsLoading,
        isUpdateLoading: updateNewsLoading,
        item: newsData?.getNews,
        loading,
        onCancel,
        onDelete,
        onEdit,
        onGenerate,
        onPublishToggle,
        onSave
    };
};
export default prepareComponent(usePrepareComponent)(Item);
