/* eslint-disable no-ternary, max-lines-per-function, max-statements */
import { useCallback, useMemo, useState } from "react";
import isNewsServiceName from "helpers/isNewsServiceName";
import useNewsCritics from "hooks/useNewsCritics";
import usePreviews from "hooks/usePreviews";
import usePreviewsNewsCritics from "hooks/usePreviewsNewsCritics";
import useReviews from "hooks/useReviews";
const DEFAULT_IS_MODAL_OPEN = false;
const useImageSelector = ({ serviceType }) => {
    const { images: newsCriticsImages } = usePreviewsNewsCritics();
    const { images: reviewsImages } = usePreviews();
    const { setImageServiceName: newsCriticsSetImageServiceName, state: { news: { imageServiceName: newsCriticsImageServiceName } } } = useNewsCritics();
    const { setImageServiceName: reviewsSetImageServiceName, state: { homeVideoReview: { imageServiceName: reviewsImageServiceName } } } = useReviews();
    const [isModalOpen, setIsModalOpen] = useState(DEFAULT_IS_MODAL_OPEN);
    const onModalClose = useCallback(() => {
        setIsModalOpen(false);
    }, [setIsModalOpen]);
    const onSelectClick = useCallback(() => {
        setIsModalOpen(true);
    }, [setIsModalOpen]);
    const images = useMemo(() => (serviceType === "news" ? newsCriticsImages : reviewsImages), [newsCriticsImages, reviewsImages, serviceType]);
    const imageServiceName = useMemo(() => serviceType === "news"
        ? newsCriticsImageServiceName
        : reviewsImageServiceName, [newsCriticsImageServiceName, reviewsImageServiceName, serviceType]);
    const onSelectFrontImage = useCallback((serviceName) => {
        if (serviceName) {
            if (serviceType === "news" && isNewsServiceName(serviceName)) {
                newsCriticsSetImageServiceName(serviceName);
            }
            else {
                reviewsSetImageServiceName(serviceName);
            }
        }
    }, [newsCriticsSetImageServiceName, reviewsSetImageServiceName, serviceType]);
    const modal = useMemo(() => ({
        isOpen: isModalOpen,
        name: "Image Selector",
        onClose: onModalClose
    }), [isModalOpen, onModalClose]);
    return useMemo(() => ({
        imageReviewsServiceNameSelected: imageServiceName,
        images,
        modal,
        onSelectClick,
        onSelectFrontImage
    }), [imageServiceName, images, modal, onSelectClick, onSelectFrontImage]);
};
export default useImageSelector;
